import React from "react"
import Image from "shared/HelpImage/index"

function CreateProfileFromTemplateInActions() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Full Circle Dashboard: My Account</h3>

      <p className="text-justify pl-3 pt-2">
        Once logged in, the dashboard is in view.
      </p>
      <p className="text-justify pl-3 pt-2">
        One thing to note is your name or company name appears in the top
        left-hand side of your screen. You can change this by clicking on the
        Pencil icon under your initials.
      </p>
      <Image src="DashboardMyAccount_Fc.png" />
      <Image src="MyAccount_Fc.png" />
      <Image src="MyAccount_Fc_Pencil.png" />
      <p className="text-justify pl-3 pt-2">
        Then click save changes and go back to the dashboard
      </p>
    </div>
  )
}
export default CreateProfileFromTemplateInActions
