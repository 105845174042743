import React, { useEffect, useState, useRef } from "react"
import { useLocation } from "react-router-dom"
import api from "api"
// import { useHarsco } from "shared"
import {
  ALL_GENERATORS,
  CURRENT_PROFILE,
  SORT_PROFILE_LIST,
  SET_PROGRESS_STEP,
  SET_NEW_PROGRESS_STEP,
  GENERATORS_LIST,
  ALL_STATES,
  ALL_CITIES,
  SET_DEFAULT_GENE,
  GET_DEFAULT_GENE,
  GENERATOR_DETAILS,
  SET_NAICS_CDDETAILS,
  SET_PROGRESS_STEP_ONLY,
  GET_ALL_PROFILE_TEMPLATES,
  PROFILE_CRETAE_FROM_GENE,
} from "store/reducer/actionType"
import { useDispatch } from "react-redux"
import { useHarsco } from "shared"
import { toast } from "react-toastify"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const dispatch = useDispatch()
    const { profile } = useHarsco()
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const firstTimeFetch = useRef(true)
    const useQuery = () => {
      return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    const generatorIdFromNotify = query.get("id")

    const getGeneratorDetails = async (id) => {
      try {
        let res = await api.g_getGeneratorDetails(id)
        if (!res) return
        dispatch({
          type: GENERATOR_DETAILS,
          payload: res,
        })
        if (res?.naics) {
          getNaicsDetails(res?.naics)
        }
      } catch (error) {}
    }

    const getNaicsDetails = async (code) => {
      let req = {
        naicsCodeKey: code,
      }
      try {
        let res = await api.p_getNAICSCodeDetals(req)
        if (!res) return
        dispatch({
          type: SET_NAICS_CDDETAILS,
          payload: res,
        })
      } catch (error) {}
    }
    const getAllGenerators = async () => {
      try {
        // let res = await api.g_getAllGenerators(customer_name)
        let res = await api.g_getAllGenerators()
        if (!res) return
        dispatch({ type: ALL_GENERATORS, payload: res })
      } catch (error) {
      } finally {
        setLoader(false)
      }
    }

    const setDefaultGenerator = async (id, payload) => {
      let req = {
        generatorId: id,
        roleId: "1",
        userName: profile?.email,
      }
      try {
        let res = await api.p_setDefaultGenerator(req)
        if (!res) return

        await dispatch({ type: SET_DEFAULT_GENE, payload: res })
        // let reqs = {
        //   roleId: "1",
        //   userName: profile?.email,
        // }
        // let resp = await api.p_getDefaultGenerator(reqs);
        // if (resp?.data) {
        //   fetchAllGenerators(payload, resp?.data)
        // } else {
        //   fetchAllGenerators(payload)
        // }
        toast.success("Default Generator has been updated.")
        fetchAllGenerators(payload)
        getDefaultGenerator()
      } catch (error) {
        toast.error("Default Generator updation failed.")
      }
    }

    const mapDefaultGenerator = async (id) => {
      await dispatch({
        type: PROFILE_CRETAE_FROM_GENE,
        payload: { isProfileFromGenerator: true, selectedProfileGenerator: id },
      })
    }

    const getDefaultGenerator = async () => {
      try {
        let res = await api.g_getDefaultGenerator()
        if (!res) {
          dispatch({ type: GET_DEFAULT_GENE, payload: "" })
        } else {
          dispatch({ type: GET_DEFAULT_GENE, payload: res })
        }
      } catch (error) {}
    }

    const checkEmptyFields = (fields) => {
      //eslint-disable-next-line
      for (const key in fields) {
        if (fields[key] !== "") {
          return false
        }
      }
      return true
    }

    const fetchAllGenerators = async (payload, defaultData) => {
      try {
        if (checkEmptyFields(payload) && !defaultData) {
          dispatch({ type: GENERATORS_LIST, payload: [] })
          return
        }
        if (payload?.generatorIds === "All") {
          payload.generatorIds = ""
        }
        let res = await api.p_searchGeneratorList(payload)
        if (!res) return
        if (defaultData) {
          dispatch({
            type: GENERATORS_LIST,
            payload: [defaultData, ...res],
          })
        } else {
          dispatch({ type: GENERATORS_LIST, payload: res })
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }

    const deleteProfile = async (profileId, payload) => {
      let req = {
        roleId: "GenericRole",
        userName: profile?.email,
        profileRequestId: profileId,
      }
      try {
        let res = await api.pu_deleteProfile(req)
        if (!res) return
        fetchAllGenerators(payload)
      } catch (error) {}
    }

    const editProfile = async (profileId) => {
      goToEditProfile("editprofile", profileId)
    }
    // useEffect(() => {
    //   getAllProfileStatus()
    // }, [])

    const goToCreateProfile = async (page) => {
      await dispatch({ type: CURRENT_PROFILE, payload: {} })
      await dispatch({
        type: SET_NEW_PROGRESS_STEP,
      })
      await dispatch({
        type: SET_PROGRESS_STEP,
        step: 1,
        progressStep: 1,
        percentage: 0,
      })
      history.push(`/profiles/${page}`)
    }
    const goToEditProfile = async (page, id) => {
      await dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: 1 })
      await dispatch({ type: GENERATOR_DETAILS, payload: {} })
      // await dispatch({
      //   type: SET_NEW_PROGRESS_STEP,
      // })
      // await dispatch({
      //   type: SET_PROGRESS_STEP,
      //   step: 0,
      //   percentage: 100,
      // })
      history.push(`/profiles/${page}/${id}`)
    }

    const goToCreateGenerator = async (page) => {
      // await dispatch({ type: CURRENT_PROFILE, payload: {} })
      history.push(`/generator/${page}`)
    }

    const searchSubmit = (payload) => {
      setLoading(true)
      fetchAllGenerators(payload)
    }

    const sortData = (options, data) => {
      dispatch({
        type: SORT_PROFILE_LIST,
        payload: options,
        data: {
          type: data?.type,
          fieldName: data?.fieldName,
        },
      })
    }

    const fetchStateInfo = async (req) => {
      const payload = {
        generatorId: req,
      }
      try {
        let res = await api.p_getAllStateInfo(payload)
        if (!res) return

        dispatch({ type: ALL_STATES, payload: res })
      } catch (error) {}
    }

    const fetchCitiesInfo = async (req) => {
      const payload = {
        generatorId: req?.generatorId,
        stateCode: req?.stateId,
      }
      try {
        let res = await api.p_getAllCitiesInfo(payload)
        if (!res) return

        dispatch({ type: ALL_CITIES, payload: res })
      } catch (error) {}
    }

    const fetchAllProfileTemplates = async () => {
      try {
        let res = await api.g_getAllProfileTemplates()
        if (!res) return
        dispatch({ type: GET_ALL_PROFILE_TEMPLATES, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      if (firstTimeFetch.current === true) {
        setLoader(true)
        getDefaultGenerator()
        getAllGenerators()
        fetchAllProfileTemplates()
        fetchStateInfo("")
        firstTimeFetch.current = false
      }
      if (generatorIdFromNotify) {
        setShowResults(true)
        const payload = {
          generatorIds: generatorIdFromNotify,
          storeID: "",
          epaid: "",
          address: "",
          state: "",
          city: "",
        }
        searchSubmit(payload)
      }
    }, [])

    const emptyCities = async () => {
      await dispatch({ type: ALL_CITIES, payload: [] })
    }

    const getProfileData = async (reqId, template, isNonDraft) => {
      let req
      if (isNonDraft) {
        req = {
          profileRequestId: 0,
          webProfileId: "",
          coreProfileNumber: reqId?.profile,
          coreVersionNumber: Number(reqId?.version),
        }
      } else {
        req = {
          profileRequestId: reqId,
        }
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        if (template)
          dispatch({
            type: CURRENT_PROFILE,
            payload: { ...res, templateFlag: true, profileName: "" },
          })
        else dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const createProfileFromTemplate = async (
      page,
      profileNumber,
      revisionNumber,
      record
    ) => {
      try {
        await mapDefaultGenerator(record)
        let res = await api.p_getCloneProfile({
          profileId: 0,
          profileNumber: profileNumber,
          revisionNum: revisionNumber,
          isProfileFromTemplate: true,
        })
        if (!res) return

        if (res?.data?.profileRequestId) {
          await getProfileData(res?.data?.profileRequestId, true)
          let pId = res?.data?.profileRequestId
          await goToEditProfile(page, pId)
        } else {
          toast.error(res?.message)
        }
      } catch (error) {}
    }

    // useEffect(() => {
    //   if (states.length > 0) {
    //     fetchCitiesInfo();
    //   } else {
    //     fetchCitiesInfo();
    //   }
    // }, [states]);

    return (
      <Component
        {...props}
        {...{
          mapDefaultGenerator,
          // updateDocData,
          sortData,
          goToCreateProfile,
          goToCreateGenerator,
          // downLoadDoc,
          searchSubmit,
          deleteProfile,
          fetchStateInfo,
          fetchCitiesInfo,
          editProfile,
          setDefaultGenerator,
          getGeneratorDetails,
          emptyCities,
          createProfileFromTemplate,
          loading,
          showResults,
          setShowResults,
          generatorIdFromNotify,
          loader,
          // changeAllDocs,
          // downloadPDF,
          // allDocsCheck,
          // pageNo,
          // gotoPage,
          // submitted,
          // viewDoc,
          // handleSearchChange,
        }}
      />
    )
  }

export default enhancer
