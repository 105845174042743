import React from "react"
import Image from "shared/HelpImage/index"

function ActionsInViewProfile() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Actions that can be performed on profile
      </h3>

      <h5 className="pt-4 text-left pl-3">Copy profile</h5>
      <Image src="Action Copy.png" />
      <p className="text-justify pl-3 pt-2">
        Copy of a profile. When copy icon is clicked a popup appears as shown
        below.
      </p>
      <Image src="Action Copy Profile Landing Page.png" />
      <p className="text-justify pl-3 pt-2">
        A name to be given for the copied profile here.
      </p>
      <Image src="Action Copy After Giving Profile Name.png" />
      <p className="text-justify pl-3 pt-2">
        Below will be the landing page when profile is copied
      </p>
      <Image src="Action Copy Profile Creation Page.png" />

      <h5 className="pt-4 text-left pl-3">Edit profile</h5>
      <Image src="Action Edit.png" />
      <p className="text-justify pl-3 pt-2">
        Edit of a profile. When edit icon is clicked, page navigated to edit
        profile as shown below.
      </p>
      <Image src="Action Edit Landing Page.png" />

      <h5 className="pt-4 text-left pl-3">Delete profile</h5>
      <Image src="Action Delete.png" />
      <p className="text-justify pl-3 pt-2">
        Delete of a profile. When delete icon is clicked a popup appears as
        shown below.
      </p>
      <Image src="Action Delete Popup.png" />

      <h5 className="pt-4 text-left pl-3">Download profile</h5>
      <Image src="Action Download.png" />

      <h5 className="pt-4 text-left pl-3">Print profile</h5>
      <Image src="Action Print.png" />
      <p className="text-justify pl-3 pt-2">
        Print of a profile. When print icon is clicked, profile is set to print
        as shown below.
      </p>
      <Image src="Action Print Page.png" />

      <h5 className="pt-4 text-left pl-3">Print Label</h5>
      <Image src="Action Print Label.png" />
      <p className="text-justify pl-3 pt-2">
        When print label icon is clicked, this will be the landing page.
      </p>
      <Image src="Action Print Label Landing Page.png" />
      <p className="text-justify pl-3 pt-2">
        After filling the form, it looks like below.
      </p>
      <Image src="Action Print Label After Filling Form.png" />
      <p className="text-justify pl-3 pt-2">
        Print label PDF will be in the format as shown below.
      </p>
      <Image src="Action Print Label PDF.png" />

      <h5 className="pt-4 text-left pl-3">Recertify of Profile</h5>
      <Image src="Action Recertify.png" />
      <p className="text-justify pl-3 pt-2">
        When recrtify icon is clicked, this will be the landing page.
      </p>
      <Image src="Action Recerify Landing Page.png" />
      <p className="text-justify pl-3 pt-2">
        The page below appears for recertifying the profile when without changes
        is selected.
      </p>
      <Image src="Action Recertify Without Change.png" />
      <p className="text-justify pl-3 pt-2">
        The page below appears for recertifying the profile when with changes is
        selected.
      </p>
      <Image src="Action Recertify With Change Page.png" />
    </div>
  )
}
export default ActionsInViewProfile
