import React from "react"
import Image from "shared/HelpImage/index"

function DefaultGenerator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Default Generator</h3>

      <p className="text-justify pl-3 pt-2">
        A popup notification will appear when selecting a Generator from the
        Search results list as your default generator.
      </p>
      <Image src="default_generator.png" />
    </div>
  )
}
export default DefaultGenerator
