import React from "react"
import Image from "shared/HelpImage/index"

function ProfileListingReports() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Profile Listing Reports</h3>
      <p className="text-justify pl-3 pt-2">
        When filters are applied to search the profile listing reports.
      </p>
      <Image src="Reports Filter Page.png" />
      <p className="text-justify pl-3 pt-2">
        Results of the applied filters of reports will be shown as below.
      </p>
      <Image src="Reports Result Page.png" />
    </div>
  )
}
export default ProfileListingReports
