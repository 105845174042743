import React from "react"
import Image from "shared/HelpImage/index"

function RequestsSummary() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Requests Summary</h3>

      <p className="text-justify pl-3 pt-2">
        The Request Summary bar graph displays the number of requests that have
        been submitted through Connect+ defaulting to the last 30 days. You have
        the option here to change the filter to include data from the following
        options: last 30 days, last 60 days, last 90 days.
      </p>
      <Image src="request_summary_30d.png" />
    </div>
  )
}
export default RequestsSummary
