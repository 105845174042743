import React from "react"
import Image from "shared/HelpImage/index"

function AdditionalInformation() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Additional Information</h3>

      <p className="text-justify pl-3 pt-2">
        Fill out all required fields marked with{" "}
        <strong className="p-0 m-0" style={{ color: "red" }}>
          *
        </strong>{" "}
        and other fields as necessary and click on Save & Continue.
      </p>
      <Image src="mni_additionalinformation.png" />
    </div>
  )
}
export default AdditionalInformation
