import React from "react"
import Image from "shared/HelpImage/index"

function GeneratorInformationAndWasteDescription() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Generator Information & Waste Description
      </h3>

      {/* <p className="text-justify pl-3 pt-2">
        This is the first step of creating profile.
      </p>
      <Image src="mni_createprofilefromblank.png" /> */}
      <h4 className="pt-4 text-left pl-3">Generator Information</h4>
      <p className="text-justify pl-3 pt-2">
        Click on Create Generator to create a new Generator. A new form will be
        displayed. Fill out all required fields marked with{" "}
        <strong className="p-0 m-0" style={{ color: "red" }}>
          *
        </strong>{" "}
        and click on submit to use your new generator. If you want to use an
        active Generator, enter the first 3 characters of an existing generator
        into the Generator search to display a drop-down menu of available
        generators to choose from.
      </p>
      <Image src="mni_generatorinformation.png" />
      <p className="text-justify pl-3 pt-2">
        When a generator is chosen, the information about the generator will be
        shown.
      </p>
      <Image src="mni_generatorinfo.png" />
      <h4 className="pt-4 text-left pl-3">Waste Description</h4>
      <p className="text-justify pl-3 pt-2">
        Enter 3 characters of a designated facility name or location in the
        Designated Facilities required field to display a drop-down menu of
        facilities to choose from. Fill out all required fields marked with{" "}
        <strong className="p-0 m-0" style={{ color: "red" }}>
          *
        </strong>{" "}
        and other fields as necessary and click on Save & Continue.
      </p>
      <Image src="mni_wastedescription.png" />
    </div>
  )
}
export default GeneratorInformationAndWasteDescription
