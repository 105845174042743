import React from "react"
import Image from "shared/HelpImage/index"

function ChemicalComposition() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Chemical Composition</h3>

      <p className="text-justify pl-3 pt-2">
        Add/Edit Constituents- You can add as many constituents as needed by
        filling out each field describing the individual constituent and then
        selecting the green ADD button.
      </p>
      <Image src="mni_chemicalcomposition.png" />
      <p className="text-justify pl-3 pt-2">
        After filling out details and clicking ADD button, a popup will appear
        of suggestions that closely match your entered constituent. You can
        select the constituent from this pop up list by selecting the
        recommendation and then clicking on ADD, or you can opt to continue with
        the information you have added yourself by selecting CONTINUE.
      </p>
      <Image src="chemicalcomposition_tri.png" />
      <p className="text-justify pl-3 pt-2">
        You will then return to the Chemical Composition page where you can
        continue to add more constituents and select Save & Continue once you
        are finished.
      </p>
      <Image src="chemicalcomposition_const.png" />
    </div>
  )
}
export default ChemicalComposition
