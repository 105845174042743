import React from "react"
import Image from "shared/HelpImage/index"

function AlertsSettings() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Alerts Settings</h3>

      <p className="text-justify pl-3 pt-2">
        Alerts settings can be done as shown below.
      </p>
      <Image src="Alerts Setting Page.png" />
    </div>
  )
}
export default AlertsSettings
