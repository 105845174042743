import React from "react"
import Image from "shared/HelpImage/index"

function Profile() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Profile</h3>

      <p className="text-justify pl-3 pt-2">
        The Profile section allows you to create a Profile from a blank page or
        a prepopulated template. You can also search for generators.
      </p>
      <Image src="mni_profile_landingpage.png" />
      {/* <p className="text-justify pl-3 pt-2">
        Lot of actions can be performed in profile page such as profile
        creation, searching, viewing , editing, copying, deleting, downloading,
        recertifying.
      </p> */}
    </div>
  )
}
export default Profile
