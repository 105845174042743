import React from "react"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = (props) => {
  let { moduleOverviewList } = props

  const data = {
    labels:
      moduleOverviewList && moduleOverviewList.length !== 0
        ? moduleOverviewList.map((item) => item.module)
        : ["Generator Creation", "Profile Creation"],
    datasets: [
      {
        label: "Modules",
        data:
          moduleOverviewList && moduleOverviewList.length !== 0
            ? moduleOverviewList.map((item) => item.requestCount)
            : [0, 0],
        backgroundColor: ["#11C4EC", "#2396EF"],
        borderWidth: 0,
        barThickness: 65,
        maxBarThickness: 65,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Number of requests originated from Connect+",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.formattedValue
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 500,
          color: "black",
          callback: function (value) {
            if (value === 0) {
              return ""
            }
            return value
          },
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Number of Requests",
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "black",
        },
        title: {
          display: true,
          text: "",
        },
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    },
    datalabels: {
      anchor: "end",
      align: "top",
      color: "black",
      font: {
        size: 12,
      },
    },
  }

  // const chartContainerStyle = {
  //   width: "400px",
  //   height: "210px",
  //   margin: "0 auto",
  //   backgroundColor: "#F6F9FB",
  //   border: "",
  //   padding: "10px",
  //   borderRadius: "20px",
  // }

  return (
    <div className="chartContainerStyle">
      <Bar data={data} options={options} />
    </div>
  )
}

export default BarChart
