import React from "react"
import { importAll } from "shared"

const images = importAll(
  require.context("assets/icon", false, /\.(png|jpe?g|svg|jfif|gif)$/)
)

const Image = ({ src, ...props }) => <img src={images[src]} {...props} />

export const PreLoad = () => (
  <div style={{ position: "absolute", zIndex: -1000, opacity: 0, bottom: 0 }}>
    {Object.keys(images).map((src) => (
      <img key={src} src={images[src]} loading="lazy" />
    ))}
  </div>
)

export default Image
