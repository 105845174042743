import React from "react"
import Image from "shared/HelpImage/index"

function ViewProfile() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">View Profile</h3>

      <p className="text-justify pl-3 pt-2">
        A profile can be viewed under this section.
      </p>
      <Image src="View Profile Page.png" />
    </div>
  )
}
export default ViewProfile
