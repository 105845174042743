import React from "react"
import Image from "shared/HelpImage/index"

function NotificationAlert() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Alerts Notification</h3>

      <p className="text-justify pl-3 pt-2">
        Alerts notification will be shown as below.
      </p>
      <Image src="Alerts Notification Page.png" />
    </div>
  )
}
export default NotificationAlert
