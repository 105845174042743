import React from "react"
import Image from "shared/HelpImage/index"

function CreateGenerator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Create Generator</h3>

      <p className="text-justify pl-3 pt-2">
        Create a Generator by selecting the Create Generator button as shown. On
        the next screen, fill out all required fields marked with{" "}
        <strong className="p-0 m-0" style={{ color: "red" }}>
          *
        </strong>{" "}
        and provide Disposal Instructions as needed. Select Submit to complete
        creating a generator.
      </p>
      <Image src="generator_creategenerator.png" />
      <br />
      <Image src="creategenerator_form1.png" />
      <br />
      <br />
      <Image src="creategenerator_form2.png" />
    </div>
  )
}
export default CreateGenerator
