import WasteProcess from "./Dashboard/WasteProcess"
import MyDocuments from "./Dashboard/MyDocuments"
import Purpose from "./Purpose"
import Login from "./Login"
import DocumentLibrary from "./Dashboard/DocumentLibrary"
import Alerts from "./Dashboard/Alerts"
import GeneratorStatusReport from "./Dashboard/GeneratorStatusReport"
import RequestService from "./Dashboard/RequestService"
import Overview from "./Overview"
import RetailDashboard from "./RetailDashboard"
import FourInformationTiles from "./RetailDashboard/FourInformationTiles"
import MyOrders from "./Dashboard/MyOrders"
import ReportGenerator from "./Dashboard/ReportGenerator"
import WasteShipmentReport from "./Dashboard/WasteShipmentReport"
import ExceptionReport from "./Dashboard/ExceptionReport"
import MyOrdersMenu from "./Dashboard/MyOrdersMenu"
import MyProfiles from "./Dashboard/MyProfiles"
import ReportServiceIssue from "./Dashboard/ReportServiceIssue"
import ChangeRequest from "./Dashboard/ChangeRequest"
import ScheduleRequest from "./Dashboard/ScheduleRequest"
import RequestSupplies from "./Dashboard/RequestSupplies"
import Support from "./Dashboard/Support"
import Logout from "./Dashboard/Logout"
import OrderOverview from "./Dashboard/OrderOverview"
import ServiceDetails from "./Dashboard/ServiceDetails"
import ProcessRelatedInformation from "./ProcessRelatedInformation"
import ForgotPassword from "./Login/ForgotPassword"
import TermsAndConditions from "./Login/TermsAndConditions"
import MyAccount from "./RetailDashboard/MyAccount"
import HeaderBar from "./RetailDashboard/HeaderBar"
import WeeklyOverview from "./RetailDashboard/WeeklyOverview"
import TermsAndTerminology from "./TermsAndTerminology"
import PPT from "./PPT"
import HomeHeaderBar from "./StoreDashboard/HeaderBar"
import StoreDocumentLibrary from "./Store/DocumentLibrary"
import StoreMyOrder from "./Store/MyOrder"
import HomePageOverView from "./StoreDashboard"
import ServiceRequest from "./Dashboard/ServiceRequest"
import RequestHistory from "./Dashboard/RequestHistory"
import FirstTimePasswordReset from "./Login/FirstTimeLoginPasswordResetWalkThrough"
import FilterFunctionality from "./Dashboard/FilterFunctionality"
import HeaderFc from "./FullCircle/Dashboard/HeaderBar"
import MyAccountFc from "./FullCircle/Dashboard/MyAccount"
import QuaterlyOverviewFc from "./FullCircle/Dashboard/QuaterlyOverview"
import ChangeUnitOfMeasureFc from "./FullCircle/Dashboard/ChangeUnitOfMeasure"
import FiveInformationTilesFc from "./FullCircle/Dashboard/FiveInformationTiles"
import WasteprocesssummaryFc from "./FullCircle/Dashboard/WasteProcessSummary"
import TopProfileReportFc from "./FullCircle/Dashboard/TopProfileReport"
import ReportGeneratorFc from "./FullCircle/ReportGenerator/ReportGenerator"
import InvoiceReportFc from "./FullCircle/ReportGenerator/InvoiceReport"
import ProfileReportFc from "./FullCircle/ReportGenerator/ProfileReport"
import MyHistoryFc from "./FullCircle/MyHistory/MyHistory"
import InvoicesFc from "./FullCircle/Invoices/Invoice"
import MyAlertsFc from "./FullCircle/MyAlerts/MyAlerts"
import CAPDB_Fc from "./FullCircle/CAPBD/CAPBD"
import Support_Fc from "./FullCircle/Support/Support"
import HistoricalLDR from "./Dashboard/HistoricalLDR"
import ExceptionGlossary from "./Dashboard/ExceptionGlossary"
import ViewingAnnouncements from "./RetailDashboard/Announcements/ViewingAnnouncements"
import AnnouncementsBanner from "./RetailDashboard/Announcements/AnnouncementsBanner"
import ViewAllAnnouncements from "./RetailDashboard/Announcements/ViewAllAnnouncements"
import Administrator from "./Dashboard/Administrator"
import EditUserAccount from "./Dashboard/EditUserAccount"
import EditUserDetails from "./Dashboard/EditUserDetails"
import CreateNewUser from "./Dashboard/CreateNewUser"
import HeaderBarMni from "./MNI/Dashboard/HeaderBar/HeaderBarMni"
import ForgotPasswordMni from "./MNI/ForgotPassword/ForgotPasswordMni"
import ProfileOverview from "./MNI/Dashboard/ProfileOverview/ProfileOverview"
import DashboardMni from "./MNI/Dashboard/DashboardMni"
import Approved from "./MNI/Dashboard/ProfileOverview/Approved"
import Expired from "./MNI/Dashboard/ProfileOverview/Expired"
import PendingCleanEarthApproval from "./MNI/Dashboard/ProfileOverview/PendingCleanEarthApproval"
import PendingCustomerResponse from "./MNI/Dashboard/ProfileOverview/PendingCustomerResponse"
import ExpiringSoon from "./MNI/Dashboard/ProfileOverview/ExpiringSoon"
import RequestsSummary from "./MNI/Dashboard/RequestsSummary/RequestsSummary"
import Generator from "./MNI/Generator/Generator"
import CreateGenerator from "./MNI/Generator/CreateGenerator"
import DefaultGenerator from "./MNI/Generator/SearchGenerator/DefaultGenerator"
import ActionsInGenerator from "./MNI/Generator/SearchGenerator/Actions/ActionsInGenerator"
import SearchGenerator from "./MNI/Generator/SearchGenerator"
import ViewGenerator from "./MNI/Generator/SearchGenerator/ViewGenerator/ViewGenerator"
import NAICSCodeDetail from "./MNI/Generator/SearchGenerator/ViewGenerator/NAICSCodeDetail"
import CreateProfileFromBlankInActions from "./MNI/Generator/SearchGenerator/Actions/CreateProfileFromBlankInActions"
import CreateProfileFromTemplateInActions from "./MNI/Generator/SearchGenerator/Actions/CreateProfileFromTemplateInActions"
import CreateProfileFromBlank from "./MNI/Profile/CreateProfileFromBlank/CreateProfileFromBlank"
import Profile from "./MNI/Profile/Profile"
import GeneratorInformationAndWasteDescription from "./MNI/Profile/CreateProfileFromBlank/GeneratorInformationAndWasteDescription"
import WasteCharacteristics from "./MNI/Profile/CreateProfileFromBlank/WasteCharacteristics"
import ChemicalComposition from "./MNI/Profile/CreateProfileFromBlank/ChemicalComposition"
import AdditionalInformation from "./MNI/Profile/CreateProfileFromBlank/AdditionalInformation"
import USEPAStateRegulatoryInformation from "./MNI/Profile/CreateProfileFromBlank/USEPAStateRegulatoryInformation"
import RegulatedConstituents from "./MNI/Profile/CreateProfileFromBlank/RegulatedConstituents"
import ShippingInformation from "./MNI/Profile/CreateProfileFromBlank/ShippingInformation"
import Documents from "./MNI/Profile/CreateProfileFromBlank/Documents"
import GeneratorCertification from "./MNI/Profile/CreateProfileFromBlank/GeneratorCertification"
import CreateProfileFromTemplate from "./MNI/Profile/CreateProfileFromTemplate"
import SelectTemplate from "./MNI/Profile/CreateProfileFromTemplate/SelectTemplate"
import SearchProfile from "./MNI/Profile/SearchProfile/SearchProfile"
import ViewProfile from "./MNI/Profile/SearchProfile/ViewProfile"
import ActionsInViewProfile from "./MNI/Profile/SearchProfile/ActionsInViewProfile"
import Reports from "./MNI/Reports"
import ProfileListingReports from "./MNI/Reports/ProfileListingReports"
import AlertsInMNI from "./MNI/Alerts/AlertsInMNI"
import NotificationAlert from "./MNI/Alerts/NotificationAlert"
import AlertsSettings from "./MNI/Alerts/AlertsSettings"
import KnowYourCSR from "./MNI/KnowYourCSR"
import LoginMni from "./MNI/Login/LoginMni"

let opts = {
  overview: Overview,
  login: Login,
  loginmni: LoginMni,
  forgotpassword: ForgotPassword,
  forgotpasswordmni: ForgotPasswordMni,
  termsandconditions: TermsAndConditions,
  retaildashboard: RetailDashboard,
  headerbar: HeaderBar,
  myaccount: MyAccount,
  weeklyoverview: WeeklyOverview,
  fourinformationtiles: FourInformationTiles,
  wasteprocesssummary: WasteProcess,
  myorders: MyOrders,
  mydocuments: MyDocuments,
  reportgenerator: ReportGenerator,
  generatorstatusreport: GeneratorStatusReport,
  wasteshipmentreport: WasteShipmentReport,
  exceptionreport: ExceptionReport,
  myordersmenu: MyOrdersMenu,
  orderoverview: OrderOverview,
  servicedetails: ServiceDetails,
  myprofiles: MyProfiles,
  documentlibrary: DocumentLibrary,
  requestservice: RequestService,
  reportserviceissue: ReportServiceIssue,
  changerequest: ChangeRequest,
  schedulerequest: ScheduleRequest,
  requestsupplies: RequestSupplies,
  alerts: Alerts,
  support: Support,
  logout: Logout,
  purpose: Purpose,
  termsandterminology: TermsAndTerminology,
  processrelatedinformation: ProcessRelatedInformation,
  ppt: PPT,
  homeheaderbar: HomeHeaderBar,
  headerbarmni: HeaderBarMni,
  storedocumentlibrary: StoreDocumentLibrary,
  storemyorder: StoreMyOrder,
  homeover: HomePageOverView,
  servicerequest: ServiceRequest,
  requesthistory: RequestHistory,
  firsttimepasswordreset: FirstTimePasswordReset,
  filterfunctionality: FilterFunctionality,
  headerFc: HeaderFc,
  myAccountFc: MyAccountFc,
  quaterlyOverviewFc: QuaterlyOverviewFc,
  changeUnitOfMeasureFc: ChangeUnitOfMeasureFc,
  fiveInformationTilesFc: FiveInformationTilesFc,
  wasteprocesssummaryFc: WasteprocesssummaryFc,
  topProfileReportFc: TopProfileReportFc,
  reportGeneratorFc: ReportGeneratorFc,
  invoiceReportFc: InvoiceReportFc,
  profileReport: ProfileReportFc,
  myHistoryFc: MyHistoryFc,
  invoicesFc: InvoicesFc,
  myAlertsFc: MyAlertsFc,
  cAPDB_Fc: CAPDB_Fc,
  support_Fc: Support_Fc,
  historicalLDR: HistoricalLDR,
  exceptionGlossary: ExceptionGlossary,
  announcementsbanner: AnnouncementsBanner,
  viewallannouncements: ViewAllAnnouncements,
  viewannouncements: ViewingAnnouncements,
  administrator: Administrator,
  createnewuser: CreateNewUser,
  edituseraccount: EditUserAccount,
  edituserdetails: EditUserDetails,
  profileoverview: ProfileOverview,
  dashboardmni: DashboardMni,
  approved: Approved,
  expired: Expired,
  pendingcustomerresponse: PendingCustomerResponse,
  pendingcleanearthapproval: PendingCleanEarthApproval,
  expiringsoon: ExpiringSoon,
  requestssummary: RequestsSummary,
  generator: Generator,
  creategenerator: CreateGenerator,
  searchgenerator: SearchGenerator,
  viewgenerator: ViewGenerator,
  naicscodedetail: NAICSCodeDetail,
  defaultgenerator: DefaultGenerator,
  actionsingenerator: ActionsInGenerator,
  createprofilefromblankinactions: CreateProfileFromBlankInActions,
  createprofilefromtemplateinactions: CreateProfileFromTemplateInActions,
  profile: Profile,
  createprofilefromblank: CreateProfileFromBlank,
  generatorinformationandwastedescription:
    GeneratorInformationAndWasteDescription,
  // creategeneratorinprofile: CreateGeneratorInProfile,
  // choosegenerator: ChooseGenerator,
  wastecharacteristics: WasteCharacteristics,
  chemicalcomposition: ChemicalComposition,
  additionalinformation: AdditionalInformation,
  usepastateregulatoryinformation: USEPAStateRegulatoryInformation,
  regulatedconstituetns: RegulatedConstituents,
  shippinginformation: ShippingInformation,
  documents: Documents,
  generatorcertification: GeneratorCertification,
  createprofilefromtemplate: CreateProfileFromTemplate,
  selecttemplate: SelectTemplate,
  searchprofile: SearchProfile,
  viewprofile: ViewProfile,
  actionsinviewprofile: ActionsInViewProfile,
  // copy: Copy,
  // print: Print,
  // download: Download,
  // edit: Edit,
  // delete: Delete,
  // recertify: Recertify,
  // recertifywithoutchange: RecerifyWithoutChange,
  // recertifywithchange: RecertifyWithChange,
  // printlabel: PrintLabel,
  reports: Reports,
  profilelistingreports: ProfileListingReports,
  alertsinmni: AlertsInMNI,
  // alertslandingpage: AlertsLandingPage,
  alertssettings: AlertsSettings,
  // emailalert: EmailAlert,
  notificationalert: NotificationAlert,
  knowyourcsr: KnowYourCSR,
}

export default opts
