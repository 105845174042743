import React from "react"
import Image from "shared/HelpImage/index"

function AlertsInMNI() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Alerts</h3>

      <p className="text-justify pl-3 pt-2">
        Below will be the landing page of Alerts.
      </p>
      <Image src="Alerts Landing Page.png" />
    </div>
  )
}
export default AlertsInMNI
