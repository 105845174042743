import React from "react"
import Image from "shared/HelpImage/index"

function CreateProfileFromTemplate() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Create Profile From Template</h3>

      <p className="text-justify pl-3 pt-2">
        A profile can be created using an existing profile template data.
      </p>
      <Image src="Profile From Template Landing Page.png" />
    </div>
  )
}
export default CreateProfileFromTemplate
