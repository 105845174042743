import React from "react"
import Image from "shared/HelpImage/index"

function LoginMni() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Login</h3>

      <p className="text-justify pl-3 pt-2">
        <div>
          To begin using Connect+, the customer enters the following URL –{" "}
          <br />
          <a
            href="https://connectplus.cleanearthinc.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://connectplus.cleanearthinc.com/
          </a>{" "}
          to access the home page.
        </div>
      </p>
      <div className="img-section">
        <Image src="login_landing_page_mni.png" alt="Login Image" />
      </div>
      <p className="text-justify pl-3 pt-2">
        You may access your account from this page by clicking the “ Login ”
        button.
      </p>
      {/* <p className="text-justify pl-3 pt-2">
       On launching connect+ URL, user is landing on a page to enter email id with login button like below
      </p>
      <div className="img-section">
        <Image src="login_landing_page_mni.png" alt="Login Image" />
      </div> */}
    </div>
  )
}
export default LoginMni
