import React from "react"
import Image from "shared/HelpImage/index"

function SelectTemplate() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Select template to create a profile
      </h3>

      <p className="text-justify pl-3 pt-2">
        Can select a template from the options
      </p>
      <Image src="Profile From Template After Selecting Options.png" />
    </div>
  )
}
export default SelectTemplate
