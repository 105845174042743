import React from "react"
import Image from "shared/HelpImage/index"

function ShippingInformation() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Shipping Information</h3>

      <p className="text-justify pl-3 pt-2">
        This step is to enter the shipping information
      </p>
      <Image src="mni_shippinginfo.png" />
    </div>
  )
}
export default ShippingInformation
