import React from "react"
import Image from "shared/HelpImage/index"

function RegulatedConstituents() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Regulated Constituents</h3>

      <p className="text-justify pl-3 pt-2">
        This step is to enter the range and unit of measure whichever
        constituent is present or not present.
      </p>
      <Image src="mni_regulatedconst.png" />
    </div>
  )
}
export default RegulatedConstituents
