import React from "react"
import Image from "shared/HelpImage/index"

function Generator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Generator</h3>

      <p className="text-justify pl-3 pt-2">
        The Generator feature in Connect+ allows the user to Create a Generator
        or search for a Generator using the available filters as displayed.
      </p>
      <Image src="mni_generator_landingpage.png" />
    </div>
  )
}
export default Generator
