import React from "react"
import Image from "shared/HelpImage/index"

function KnowYourCSR() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Know Your CSR</h3>

      <p className="text-justify pl-3 pt-2">
        The information to contact Customer Service Representative will be shown
        as below.
      </p>
      <Image src="knowyour_csr.png" />
    </div>
  )
}
export default KnowYourCSR
