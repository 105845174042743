import React from "react"
import Image from "shared/HelpImage/index"

function DashboardMni() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">M&I Dashboard</h3>
      <p className="text-justify pl-3 pt-3">
        Once logged in, the dashboard is in view. Menu Options are available
        descending the left side of the screen. At the top you will find your
        name. If you click on the pencil icon with your name, you can edit your
        name, phone number and Job title. Make sure to select Save Changes.
      </p>
      <Image src="account_name_dashboard.png" />
    </div>
  )
}
export default DashboardMni
