export const help = [
  {
    label: "Login",
    contentKey: "login",
    subTopics: [
      {
        label: "Forgot Password – Password Reset Walkthrough ",
        contentKey: "forgotpassword",
      },
      {
        label: "Terms and conditions ",
        contentKey: "termsandconditions",
      },
    ],
  },
  {
    label: "Retail",
    contentKey: "login",
    subTopics: [
      {
        label: "Dashboard",
        contentKey: "headerbar",
        subTopics: [
          {
            label: "Connect+ Header Bar",
            contentKey: "headerbar",
          },
          {
            label: "Viewing Announcements",
            contentKey: "viewannouncements",
          },
          {
            label: "Announcements Banner",
            contentKey: "announcementsbanner",
          },
          {
            label: "View All Announcements",
            contentKey: "viewallannouncements",
          },
          {
            label: "My Account",
            contentKey: "myaccount",
          },
          {
            label: "Weekly Overview",
            contentKey: "weeklyoverview",
          },
          {
            label: "Informational Tiles",
            contentKey: "fourinformationtiles",
          },
          {
            label: "Waste Process Summary",
            contentKey: "wasteprocesssummary",
          },
          {
            label: "My Orders",
            contentKey: "myorders",
          },
          {
            label: "My Documents",
            contentKey: "mydocuments",
          },
        ],
      },
      {
        label: "Report Generator",
        contentKey: "reportgenerator",
        subTopics: [
          {
            label: "Generator Status Report",
            contentKey: "generatorstatusreport",
          },
          {
            label: "Waste Shipment Summary Report ",
            contentKey: "wasteshipmentreport",
          },
          {
            label: "Exception Report",
            contentKey: "exceptionreport",
            subTopics: [
              {
                label: "Exceptions Glossary",
                contentKey: "exceptionGlossary",
              },
            ],
          },
        ],
      },
      {
        label: "My Orders",
        contentKey: "myordersmenu",
        subTopics: [
          {
            label: "Order Overview",
            contentKey: "orderoverview",
          },
          {
            label: "Service Details",
            contentKey: "servicedetails",
          },
        ],
      },
      {
        label: "My Profiles",
        contentKey: "myprofiles",
      },
      {
        label: "Document Library",
        contentKey: "documentlibrary",
        subTopics: [
          {
            label: "Historical LDRs",
            contentKey: "historicalLDR",
          },
        ],
      },
      {
        label: "Service Requests",
        contentKey: "servicerequest",
        subTopics: [
          {
            label: "New Request",
            contentKey: "requestservice",
            subTopics: [
              {
                label: "Report a Service issue",
                contentKey: "reportserviceissue",
              },
              {
                label: "Change Request",
                contentKey: "changerequest",
              },
              {
                label: "New Schedule Request",
                contentKey: "schedulerequest",
              },
              {
                label: "Request Supplies",
                contentKey: "requestsupplies",
              },
            ],
          },
          {
            label: "Request History",
            contentKey: "requesthistory",
          },
        ],
      },
      {
        label: "My Alerts",
        contentKey: "alerts",
      },
      {
        label: "Administration",
        contentKey: "administrator",
        subTopics: [
          {
            label: "Create New User",
            contentKey: "createnewuser",
          },
          {
            label: "Edit User Details",
            contentKey: "edituserdetails",
            subTopics: [
              {
                label: "Edit User Account",
                contentKey: "edituseraccount",
              },
            ],
          },
        ],
      },
      {
        label: "Support",
        contentKey: "support",
      },
      {
        label: "Logout",
        contentKey: "logout",
      },
    ],
  },
  {
    label: "Terms and Terminologies",
    contentKey: "termsandterminology",
  },
  // {
  //   label: "PPT",
  //   contentKey: "ppt",
  // },
]

export const helpStore = [
  {
    label: "Login",
    contentKey: "login",
    subTopics: [
      {
        label: "Forgot password",
        contentKey: "forgotpassword",
      },
      {
        label: "Terms and conditions",
        contentKey: "termsandconditions",
      },
    ],
  },
  {
    label: "Store",
    contentKey: "login",
    subTopics: [
      {
        label: "Home Overview",
        contentKey: "homeover",
        subTopics: [
          {
            label: "Connect+ Header Bar",
            contentKey: "homeheaderbar",
          },
        ],
      },
      {
        label: "My Orders",
        contentKey: "storemyorder",
      },
      {
        label: "Document Library",
        contentKey: "storedocumentlibrary",
      },
      // {
      //   label: "Support",
      //   contentKey: "support",
      // },
      {
        label: "Logout",
        contentKey: "logout",
      },
    ],
  },
  {
    label: "Terms and Terminologies",
    contentKey: "termsandterminology",
  },
]

export const fullCircleHelp = [
  {
    label: "Login",
    contentKey: "login",
    subTopics: [
      {
        label: "Forgot Password – Password Reset Walkthrough ",
        contentKey: "forgotpassword",
      },
      {
        label: "First Time Login – Password Reset Walkthrough",
        contentKey: "firsttimepasswordreset",
      },
      {
        label: "Terms and conditions ",
        contentKey: "termsandconditions",
      },
    ],
  },
  {
    label: "Full Circle",
    contentKey: "login",
    subTopics: [
      {
        label: "Dashboard",
        contentKey: "headerFc",
        subTopics: [
          {
            label: "Connect+ Header Bar",
            contentKey: "headerFc",
          },
          {
            label: "My Account",
            contentKey: "myAccountFc",
          },
          {
            label: "Quaterly Overview",
            contentKey: "quaterlyOverviewFc",
          },
          {
            label: "Change Unit of measure on Dashboard",
            contentKey: "changeUnitOfMeasureFc",
          },
          {
            label: "Five Information Tiles",
            contentKey: "fiveInformationTilesFc",
          },
          {
            label: "Waste Process Summary",
            contentKey: "wasteprocesssummaryFc",
          },
          {
            label: "Top 10 Profile Report",
            contentKey: "topProfileReportFc",
          },
        ],
      },
      {
        label: "Report Generator",
        contentKey: "reportGeneratorFc",
        subTopics: [
          {
            label: "Invoice Report",
            contentKey: "invoiceReportFc",
          },
          {
            label: "Profile Report",
            contentKey: "profileReport",
          },
        ],
      },
      {
        label: "My History",
        contentKey: "myHistoryFc",
      },
      {
        label: "Invoices",
        contentKey: "invoicesFc",
      },
      {
        label: "My Alerts",
        contentKey: "myAlertsFc",
      },
      {
        label: "CAPDB",
        contentKey: "cAPDB_Fc",
      },
      {
        label: "Support",
        contentKey: "support_Fc",
      },
      {
        label: "Log Out",
        contentKey: "logout",
      },
    ],
  },
]

export const mnihelp = [
  {
    label: "Login",
    contentKey: "loginmni",
    subTopics: [
      {
        label: "Forgot Password – Password Reset Walkthrough ",
        contentKey: "forgotpasswordmni",
      },
    ],
  },
  {
    label: "M&I",
    contentKey: "login",
    subTopics: [
      {
        label: "Connect+ Header Bar",
        contentKey: "headerbarmni",
      },
      {
        label: "Viewing Announcements",
        contentKey: "viewannouncements",
      },
      {
        label: "Announcements Banner",
        contentKey: "announcementsbanner",
      },
      {
        label: "View All Announcements",
        contentKey: "viewallannouncements",
      },
      {
        label: "Dashboard",
        contentKey: "dashboardmni",
        subTopics: [
          {
            label: "Profile Overview",
            contentKey: "profileoverview",
            // subTopics: [
            //   {
            //     label: "Approved",
            //     contentKey: "approved",
            //   },
            //   {
            //     label: "Expired",
            //     contentKey: "expired",
            //   },
            //   {
            //     label: "Pending Customer response",
            //     contentKey: "pendingcustomerresponse",
            //   },
            //   {
            //     label: "Pending Clean Earth Approval",
            //     contentKey: "pendingcleanearthapproval",
            //   },
            //   {
            //     label: "Expiring Soon",
            //     contentKey: "expiringsoon",
            //   },
            // ],
          },
          {
            label: "Requests Summary",
            contentKey: "requestssummary",
          },
        ],
      },
      {
        label: "Generator",
        contentKey: "generator",
        subTopics: [
          {
            label: "Create Generator",
            contentKey: "creategenerator",
          },
          {
            label: "Search Generator",
            contentKey: "searchgenerator",
            subTopics: [
              {
                label: "View Generator",
                contentKey: "viewgenerator",
                subTopics: [
                  {
                    label: "NAICS Code detail",
                    contentKey: "naicscodedetail",
                  },
                ],
              },
              {
                label: "Default Generator",
                contentKey: "defaultgenerator",
              },
              {
                label: "Actions",
                contentKey: "actionsingenerator",
                subTopics: [
                  {
                    label: "Create Profile From Blank",
                    contentKey: "createprofilefromblankinactions",
                  },
                  {
                    label: "Create Profile From Template",
                    contentKey: "createprofilefromtemplateinactions",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Profile",
        contentKey: "profile",
        subTopics: [
          {
            label: "Create Profile from Blank",
            contentKey: "generatorinformationandwastedescription",
            subTopics: [
              {
                label: "Generator Information & Waste Description",
                contentKey: "generatorinformationandwastedescription",
                subTopics: [
                  {
                    label: "Create Generator",
                    contentKey: "creategeneratorinprofile",
                  },
                  {
                    label: "Choose Generator",
                    contentKey: "choosegenerator",
                  },
                ],
              },
              {
                label: "Waste Characteristics(@70°F)",
                contentKey: "wastecharacteristics",
              },
              {
                label: "Chemical Composition",
                contentKey: "chemicalcomposition",
              },
              {
                label: "Additional Information",
                contentKey: "additionalinformation",
              },
              {
                label: "US EPA / State Regulatory Information",
                contentKey: "usepastateregulatoryinformation",
              },
              {
                label: "Regulated Constituents",
                contentKey: "regulatedconstituetns",
              },
              {
                label: "Shipping Information",
                contentKey: "shippinginformation",
              },
              {
                label: "Documents",
                contentKey: "documents",
              },
              {
                label: "Generator Certification",
                contentKey: "generatorcertification",
              },
            ],
          },
          {
            label: "Create Profile From Template",
            contentKey: "createprofilefromtemplate",
            subTopics: [
              {
                label: "Select Template",
                contentKey: "selecttemplate",
              },
            ],
          },
          {
            label: "Search Profile",
            contentKey: "searchprofile",
            subTopics: [
              {
                label: "View Profile",
                contentKey: "viewprofile",
              },
              {
                label: "Actions",
                contentKey: "actionsinviewprofile",
                subTopics: [
                  {
                    label: "Copy",
                    contentKey: "copy",
                  },
                  {
                    label: "Print",
                    contentKey: "print",
                  },
                  {
                    label: "Download",
                    contentKey: "download",
                  },
                  {
                    label: "Edit",
                    contentKey: "edit",
                  },
                  {
                    label: "Delete",
                    contentKey: "delete",
                  },
                  {
                    label: "Recertify",
                    contentKey: "recertify",
                    subTopics: [
                      {
                        label: "Recertify Without Change",
                        contentKey: "recertifywithoutchange",
                      },
                      {
                        label: "Recertify With Change",
                        contentKey: "recertifywithchange",
                      },
                    ],
                  },
                  {
                    label: "Print Label",
                    contentKey: "printlabel",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Reports",
        contentKey: "reports",
        subTopics: [
          {
            label: "Profile Listing Reports",
            contentKey: "profilelistingreports",
          },
        ],
      },
      {
        label: "Alerts",
        contentKey: "alertsinmni",
        subTopics: [
          // {
          //   label: "Alerts Landing Page",
          //   contentKey: "alertsinmni",
          // },
          {
            label: "Alerts Settings",
            contentKey: "alertssettings",
            subTopics: [
              // {
              //   label: "Email Alert",
              //   contentKey: "emailalert",
              // },
              {
                label: "Notification Alert",
                contentKey: "notificationalert",
              },
            ],
          },
        ],
      },
      {
        label: "Know Your CSR",
        contentKey: "knowyourcsr",
      },
      // {
      //   label: "Administration",
      //   contentKey: "administration",
      //   subTopics: [
      //     {
      //       label: "Create a New User ",
      //       contentKey: "createanewuser",
      //     },
      //     {
      //       label: "Edit User Details",
      //       contentKey: "edituserdetails",
      //     },
      //     {
      //       label: "Create New Announcement",
      //       contentKey: "createnewannouncement",
      //     },
      //     {
      //       label: "Edit or Delete Announcements",
      //       contentKey: "editordeleteannouncements",
      //     },
      //   ],
      // },
      {
        label: "Logout",
        contentKey: "logout",
      },
    ],
  },
  // {
  //   label: "Terms and Terminologies",
  //   contentKey: "termsandterminology",
  // },
  // {
  //   label: "PPT",
  //   contentKey: "ppt",
  // },
]
