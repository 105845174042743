import React from "react"
import Image from "shared/HelpImage/index"

function SearchGenerator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Search Generator</h3>

      <p className="text-justify pl-3 pt-2">
        Search for an existing generator by using the following search filters
        which are available as displayed: Generator, EPA ID, Address, State,
        City. If you know the generator name you can select them from the
        drop-down menu in the generator field or opt to Select All from this
        menu to view all generators.
      </p>
      <p className="text-justify pl-3 pt-2">
        Enter any information you may have about the generator you are searching
        for in the other fields and select the search button to view your search
        results below. Select the clear filters button if you want to start a
        new search.
      </p>
      <Image src="search_gen_result.png" />
    </div>
  )
}
export default SearchGenerator
