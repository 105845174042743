import React from "react"
import Image from "shared/HelpImage/index"

function ViewGenerator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">View Generator</h3>

      <p className="text-justify pl-3 pt-2">
        From the search results displayed, you can click on the Generator name
        to view generator details.
      </p>
      <Image src="viewgenerator.png" />
      <p className="text-justify pl-3 pt-2">
        Hover over the NAICS Code in the General Information section of
        generator to display more detailed information as shown:
      </p>
      <Image src="naicscode.png" />
    </div>
  )
}
export default ViewGenerator
