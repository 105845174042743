import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHarsco, useIsTablet, useWindowDimension } from "shared"
import { PreLoad } from "shared/Image"
import { useLocation } from "react-router-dom"
import Header from "./header"
import Sidebar from "./sidebar"
import Footer from "./footer"
import Routes from "routes"
import Login from "routes/LoginS"
import Logout from "routes/Logout"
import Unauthorised from "routes/Unauthorised"
import OutsideClickHandler from "react-outside-click-handler"
import classnames from "classnames"
import _ from "lodash"
import LegalAgreement from "routes/LegalAgreement"
import EditProfile from "routes/EditProfile"
import PdfViewer from "routes/PdfViewer"
import "./style.scss"
import QuickTour from "./QuickTour"
import { usePageViews } from "shared/gtag"
import Announcements from "./Announcements"
import { logOut } from "msal"
import api from "api"
import SessionTimeOut from "./SessionTimeOut/session"
// import  { roles } from "config"
import { BusinessSegmentId } from "config"
let timerId
let timeRunning
let timeoutId

function MainWrapper(props) {
  usePageViews()
  const { user, profile } = useSelector((state) => state)
  let [height, setHeight] = useState(0)
  let [hasSidebar, setHasSidebar] = useState(false)
  const [count, setCount] = useState(1)
  const [loading, setLoading] = useState("")
  const [fontLoaded, setFontLoaded] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const { isStoreManager } = useHarsco()

  const [isPopOverTour, setIsPopOverTour] = useState(false)
  const [isQuickTourCompleted, setIsQuickTourCompleted] = useState(true)
  const [showAnnouncements, setShowAnnouncements] = useState(false)
  const [tickerAnnouncements, setTickerAnnouncements] = useState(0)
  const [announcementStatus, setAnnounceStatus] = useState(false)

  const [sessionTimeOut, setSessionTimeOut] = useState(false)

  //when global customer is changed
  const { changeCustomer } = useSelector((state) => state)

  const { announcements = [], lastViewedAnnouncement = 0 } = profile
  const { userPreferences } = profile
  const { is_quickTour_completed } = userPreferences
    ? JSON.parse(userPreferences)
    : false

  let toggleSidebar = () => {
    if (count % 2 != 0) {
      setCount(count + 1)
      setHasSidebar(true)
    } else {
      setCount(count + 1)
      setHasSidebar(false)
    }
  }
  let isTablet = useIsTablet()
  let loggedIn = !_.isEmpty(user)
  let dim = useWindowDimension()
  let location = useLocation()

  document.fonts.onloading = () => {
    if (!fontLoaded) setLoading(true)
  }
  document.fonts.onloadingdone = () => {
    setLoading(false)
    setFontLoaded(true)
  }

  useEffect(() => {
    setHeight(window.innerHeight - 112)
  }, dim)

  useEffect(() => {
    setHasSidebar(!isTablet)
  }, [isTablet])

  const timerStep = 115 * 60 * 1000

  const sessionAlert = () => {
    timeRunning = setTimeout(() => {
      logout()
    }, 5 * 60 * 1000)
  }

  const startTimer = () => {
    if (timeRunning) clearTimeout(timeRunning)
    if (timerId) clearTimeout(timerId)
    timerId = setTimeout(() => {
      setSessionTimeOut(true)
      sessionAlert()
    }, timerStep)
  }

  const stopTimer = () => clearTimeout(timerId)

  useEffect(() => {
    // start timer when component is mounted
    if (loggedIn) startTimer()
    // stop timer when component is unmounted
    return stopTimer
  }, [])

  const getAnnouncements = (array, key) => {
    return array.filter((ele) => ele?.id > key)
  }

  const logout = async () => {
    await api.pa_logOut()
    await logOut()
  }

  const mouseMovementsAndKeyBoardEvents = () => {
    if (!sessionTimeOut) {
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => {
        startTimer()
      }, 2000)
    }
  }

  const checkQuickTour = () => {
    if (profile?.businessSegmentId === BusinessSegmentId.FULL_CIRCLE) {
      return true
    } else {
      return is_quickTour_completed
    }
  }
  if (loggedIn) {
    const { isLegalyAccepted, phone, isStoreLevelUser } = profile
    if (user.noAccess) return <Unauthorised />
    if (!isLegalyAccepted) return <LegalAgreement />
    if (!phone && !isStoreLevelUser) return <EditProfile />
    if (
      isLegalyAccepted &&
      ((phone && !isStoreManager) || isStoreManager) &&
      !isQuickTourCompleted &&
      !isPopOverTour &&
      !isTourOpen &&
      !(profile?.businessSegmentId === BusinessSegmentId.FULL_CIRCLE)
    ) {
      document.body.style.overflowY = "hidden"
      setIsPopOverTour(true)
    }
    if (
      checkQuickTour() &&
      !showAnnouncements &&
      getAnnouncements(announcements, lastViewedAnnouncement).length > 0
    ) {
      setShowAnnouncements(true)
    }
  }
  if (location.pathname == "/pdfviewer") return <PdfViewer />

  if (loading) {
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <div className={`App ${isTablet ? "isTablet" : ""}`}>
      {!loggedIn ? (
        location.pathname == "/logout" ? (
          <Logout />
        ) : (
          <Login />
        )
      ) : (
        <div
          className={classnames("wrapper", {
            resized: !isTablet && dim[0] < 1280,
          })}
          onKeyDown={(e) => mouseMovementsAndKeyBoardEvents()}
          onMouseUp={(e) => mouseMovementsAndKeyBoardEvents()}
          onMouseMove={() => mouseMovementsAndKeyBoardEvents()}
        >
          <div
            className={`header ${
              (changeCustomer?.status || showAnnouncements || sessionTimeOut) &&
              "prevent-click"
            }`}
          >
            {loading === false ? (
              <Header {...{ toggleSidebar, isTablet, fontLoaded }} />
            ) : null}
          </div>
          <div className="main">
            <div
              className={`clean-earth-container 
              ${changeCustomer?.status && "prevent-click"}`}
            >
              <div
                className="row headerbelow"
                style={{
                  minHeight: location.pathname === "/help" ? "unset" : height,
                }}
              >
                <OutsideClickHandler
                  onOutsideClick={() => {
                    isTablet && setHasSidebar(false)
                  }}
                  disabled={!isTablet}
                  display="contents"
                >
                  {hasSidebar &&
                    location.pathname != "/help" &&
                    !location.pathname.includes("/requestHistoryDetail/") &&
                    !location.pathname.includes(
                      "/myschedule/serviceorder/"
                    ) && (
                      <Sidebar
                        {...{
                          isTablet,
                          toggleSidebar,
                          isQuickTourCompleted,
                          loading,
                        }}
                      />
                    )}
                </OutsideClickHandler>
                {loading === false ? (
                  <Routes
                    {...{
                      isTablet,
                      toggleSidebar,
                      hasSidebar,
                      setShowAnnouncements,
                      setTickerAnnouncements,
                      showAnnouncements,
                      announcementStatus,
                    }}
                  />
                ) : null}

                {profile?.businessSegmentId !== BusinessSegmentId?.["M&I"] && (
                  <QuickTour
                    {...{
                      isTourOpen,
                      setIsTourOpen,
                      isQuickTourCompleted,
                      setIsQuickTourCompleted,
                      isStoreManager,
                      isPopOverTour,
                      setIsPopOverTour,
                      profile,
                    }}
                  />
                )}
                {showAnnouncements && (
                  // Below code was restricting the header while retail users have announcements
                  // profile?.roleId !== roles.CORPORATE_USER &&
                  <Announcements
                    announcementsToShow={getAnnouncements(
                      announcements,
                      lastViewedAnnouncement
                    )}
                    allAnnouncements={announcements}
                    open={showAnnouncements}
                    setAnnouncements={setShowAnnouncements}
                    tickerAnnouncements={tickerAnnouncements}
                    setAnnounceStatus={setAnnounceStatus}
                  />
                )}
                {sessionTimeOut && (
                  <SessionTimeOut
                    sessionTimeOut={sessionTimeOut}
                    setSessionTimeOut={setSessionTimeOut}
                    logout={logout}
                    startTimer={startTimer}
                  />
                )}
              </div>
            </div>
          </div>
          {loading === false ? (
            <div className="footer">
              <Footer />
              <PreLoad />
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}
export default MainWrapper
