import React, { Fragment, useEffect, useRef } from "react"
import wastecharacterenhancer from "./wastecharacterenhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
// import {Select} from "shared/Formik"
// import classnames from "classnames"
import Select from "shared/Select"
import Input from "routes/Admin/Components/Input"
import TextArea from "routes/Admin/Components/TextArea"
import Radio from "shared/Radio"
import { Form, Formik } from "formik"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import AlertModal from "./AlertModal"

function WasteCharacteristics(props) {
  let {
    formik,
    backAction,
    odorOpts,
    tocOpts,
    layersOpts,
    btulbOpts,
    viscosityOpts,
    boilingPointOpts,
    specificGravityOpts,
    flashPointOpts,
    corrosivityOpts,
    cancelAction,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    genNameforView,
  } = props
  const { type } = useParams()
  const ref = useRef()
  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }

  useEffect(() => {
    if (showAlert) {
      ref.current.click()
    }
  }, [showAlert])

  return (
    <Fragment>
      {/* Waste Characteristics */}
      <Formik
        enableReinitialize={true}
        initialValues={formik?.values}
        {...formik}
      >
        {({ values, errors, touched, setFieldValue, isValid }) => {
          // debugger
          const handleRadioChange = (name) => {
            setFieldValue("wasteCharNoSelectionFlag", false)
            setFieldValue("voc100Ppm", false)
            setFieldValue("voc500Ppm", false)
            setFieldValue(name, true)
          }

          return (
            <Form>
              <div
                className="waste-charac"
                style={{
                  pointerEvents: type === "viewprofile" ? "none" : "auto",
                }}
              >
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left ml-4 py-4">
                  <div
                    className="m-0 myprofile"
                    style={{
                      color: "#337AB7",
                      fontWeight: "bold",
                    }}
                  >
                    <FormattedMessage id="profile.wastecharacteristics" />
                  </div>
                  <div className="form-input-block col-sm-4">
                    <span className="label">
                      <FormattedMessage id="profile.generator" />
                    </span>
                    <Input
                      value={
                        getDefaultGeneratorName(currentProfile?.generatorId) ||
                        genNameforView
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: "15px 30px",
                    background: "#F6F9FB",
                    borderRadius: 20,
                    padding: "15px 0 30px 0",
                  }}
                >
                  <div
                    className="d-flex col-sm-12 col-md-12 col-lg-12"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div style={{ marginLeft: "-5px" }}>
                      <span className=" text-description p-1 text-left">
                        <FormattedMessage id="profile.layers" />
                      </span>
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div style={{ position: "relative", left: 23 }}>
                      <span className=" text-description p-1 text-left">
                        <FormattedMessage id="profile.viscosity" />
                      </span>
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div style={{ position: "relative", left: 24 }}>
                      <span className=" text-description p-1 text-left">
                        <FormattedMessage id="profile.specificgravity" />
                      </span>
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div style={{ marginRight: "-20px" }}>
                      <span className=" text-description p-1 text-left">
                        <FormattedMessage id="profile.flashpoint" />
                      </span>
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div style={{ position: "relative", left: 10 }}>
                      <span className=" text-description p-1 text-left">
                        <FormattedMessage id="profile.corrosivity" />
                      </span>
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div style={{ marginRight: "15px" }}>
                      <span className=" text-description p-1 text-left">
                        <FormattedMessage id="profile.btulb" />
                      </span>
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <span className=" text-description p-1 text-left">
                        <FormattedMessage id="profile.boilingpoint" />
                      </span>
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                  </div>
                  <div
                    className="d-flex col-sm-12 col-md-12 col-lg-12 py-2 waste-char"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div className="col-sm-3 p-0">
                      <div
                        className="d-flex col-sm-12"
                        style={{
                          justifyContent: "space-around",
                          // marginLeft: "0.25rem",
                        }}
                      >
                        <div className="col-sm-6 p-0">
                          <div
                            className="form-input-block mb-dropdown mni-style layers-dropdown-height"
                            style={{
                              width: 114,
                              position: "relative",
                              left: -9,
                            }}
                          >
                            <Select
                              disabled={type === "viewprofile"}
                              name="layered"
                              label=""
                              labelkey={"l"}
                              id={"wc"}
                              className={`sort-dropdown ${
                                values?.layered?.v == null
                                  ? "mni-selected-dropdown"
                                  : ""
                              }`}
                              options={layersOpts.options}
                              selected={values?.layered}
                              onSelect={(e) => {
                                setFieldValue("layered", e)
                              }}
                            />
                          </div>
                          <div
                            style={{
                              color: "red",
                              fontSize: 10,
                              paddingTop: 10,
                            }}
                          >
                            {errors.layered && touched.layered ? (
                              <div style={{ marginLeft: "-25px" }}>
                                Layers is required
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-6 p-0 ml-1">
                          <div
                            className="form-input-block mb-dropdown mni-style viscosity-dropdown-height"
                            style={{
                              width: 120,
                              position: "relative",
                              left: 23,
                            }}
                          >
                            <Select
                              name="viscosity"
                              // style={{overFlow: "hidden"}}
                              label=""
                              labelkey={"l"}
                              id={"wc"}
                              className={`sort-dropdown ${
                                values?.viscosity?.v == 0
                                  ? "mni-selected-dropdown"
                                  : ""
                              }`}
                              options={viscosityOpts.options}
                              selected={values?.viscosity}
                              onSelect={(e) => {
                                setFieldValue("viscosity", e)
                              }}
                              disabled={type === "viewprofile"}
                            />
                          </div>
                          <div
                            style={{
                              color: "red",
                              fontSize: 10,
                              paddingTop: 10,
                            }}
                          >
                            {errors.viscosity?.id && touched.viscosity?.id ? (
                              <div style={{ position: "relative", left: 18 }}>
                                Viscosity is required
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-sm-2"
                      style={{ position: "relative", left: 9 }}
                    >
                      <div
                        className="form-input-block ml-2 mni-style"
                        style={{
                          width: "80%",
                          position: "relative",
                          left: 23,
                          marginBottom: 85,
                        }}
                      >
                        <Select
                          name="specificGravity"
                          className={`sort-dropdown ${
                            values?.specificGravity?.v == 0
                              ? "mni-selected-dropdown"
                              : ""
                          }`}
                          options={specificGravityOpts.options}
                          selected={values?.specificGravity}
                          onSelect={(e) => {
                            if (e.l === "N/A") {
                              setFieldValue("specificGravityActual", "")
                            }
                            setFieldValue("specificGravity", e)
                          }}
                          disabled={type === "viewprofile"}
                        />
                        <div
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "absolute",
                            top: 40,
                            // paddingTop: "40px",
                          }}
                        >
                          {errors.specificGravity && touched.specificGravity ? (
                            <div style={{ marginLeft: "-26px" }}>
                              Specific Gravity is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="form-input-block mt-4"
                        style={{
                          marginLeft: "15px",
                          width: "80%",
                          // position: "relative",
                          // left: 5,
                          // top: 17,
                        }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.actual" />
                        </span>
                        <Input
                          name="specificGravityActual"
                          // value={values?.specificGravityActual}
                          value={
                            values?.specificGravity &&
                            (values?.specificGravity.v === 0 ||
                              values?.specificGravity.v === null)
                              ? (values["specificGravityActual"] = "")
                              : values?.specificGravityActual
                          }
                          type="number"
                          disabled={
                            values?.specificGravity &&
                            (values?.specificGravity.v === 0 ||
                              values?.specificGravity.v === null)
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("specificGravityActual", value)
                            }
                          }}
                          onBlurChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue(
                                "specificGravityActual",
                                value || ""
                              )
                            }
                          }}
                        />
                        <div
                          style={{
                            color: "red",
                            fontSize: 10,
                            paddingTop: 10,
                            position: "absolute",
                            top: 36,
                          }}
                        >
                          {errors.specificGravityActual &&
                          touched.specificGravityActual ? (
                            <div>{errors.specificGravityActual}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ position: "relative", left: 9 }}>
                      <div
                        className="form-input-block ml-2 mni-style"
                        style={{ width: "95%", marginBottom: 85 }}
                      >
                        <Select
                          label=""
                          name="flashPointFlag"
                          labelkey={"l"}
                          id={"wc"}
                          className={`sort-dropdown ${
                            values?.flashPointFlag?.v == 0
                              ? "mni-selected-dropdown"
                              : ""
                          }`}
                          options={flashPointOpts.options}
                          selected={values?.flashPointFlag}
                          onSelect={(e) => {
                            if (e.l === "N/A") {
                              setFieldValue("flashpointActual", "")
                              setFieldValue("flashpointScale", "")
                            }
                            setFieldValue("flashPointFlag", e)
                          }}
                          disabled={type === "viewprofile"}
                        />
                      </div>
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "absolute",
                          top: 40,
                          left: 2,
                        }}
                      >
                        {errors?.flashPointFlag && touched?.flashPointFlag ? (
                          <div>Flash Point is required</div>
                        ) : null}
                      </div>
                      <div
                        className="form-input-block mt-4"
                        style={{
                          // marginTop: "1.8rem",
                          width: "95%",
                          position: "relative",
                          right: 5,
                          // top: 17,
                        }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.actual" />
                        </span>
                        <Input
                          name="flashpointActual"
                          // value={values?.flashpointActual}
                          value={
                            values?.flashPointFlag &&
                            (values?.flashPointFlag.v === 0 ||
                              values?.flashPointFlag.v === null)
                              ? (values["flashpointActual"] = "")
                              : values?.flashpointActual
                          }
                          type="number"
                          disabled={
                            values?.flashPointFlag &&
                            (values?.flashPointFlag.v === 0 ||
                              values?.flashPointFlag.v === null)
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("flashpointActual", value)
                            }
                          }}
                          onBlurChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("flashpointActual", value || "")
                            }
                          }}
                        />
                        <div
                          style={{ color: "red", fontSize: 10, paddingTop: 10 }}
                        >
                          {errors.flashpointActual &&
                          touched.flashpointActual ? (
                            <div>{errors.flashpointActual}</div>
                          ) : null}
                        </div>
                        <div
                          className="d-flex mt-2"
                          style={{ justifyContent: "center" }}
                        >
                          <span className="d-flex">
                            {/* <Radio name="flashpointScale" /> */}
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="flashpointScale"
                              // value={values?.flashpointScale}
                              value={
                                values?.flashPointFlag.v === 0 ||
                                values?.flashpointActual === ""
                                  ? (values.flashpointScale = "")
                                  : values?.flashpointScale
                              }
                              checked={
                                values?.flashpointScale === "F" ? true : false
                              }
                              onChange={() => {
                                setFieldValue("flashpointScale", "F")
                              }}
                            />
                            <span className="ml-3" style={{ fontSize: 12 }}>
                              <FormattedMessage id="profile.degreeF" />
                            </span>
                          </span>
                          <span className="d-flex ml-2">
                            {/* <Radio name="flashpointScale" value={values?.flashpointScale} /> */}
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="flashpointScale"
                              // value={values?.flashpointScale}
                              value={
                                values?.flashPointFlag.v === 0 ||
                                values?.flashpointActual === ""
                                  ? (values.flashpointScale = "")
                                  : values?.flashpointScale
                              }
                              checked={
                                values?.flashpointScale === "C" ? true : false
                              }
                              onChange={() => {
                                setFieldValue("flashpointScale", "C")
                              }}
                            />
                            <span className="ml-3" style={{ fontSize: 12 }}>
                              <FormattedMessage id="profile.degreeC" />
                            </span>
                          </span>
                        </div>
                        <div
                          style={{ color: "red", fontSize: 10, paddingTop: 10 }}
                        >
                          {errors.flashpointScale && touched.flashpointScale ? (
                            <div>{errors.flashpointScale}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ padding: "0 0.5rem" }}>
                      <div
                        className="form-input-block ml-3 mni-style"
                        style={{ width: "90%", marginBottom: 85 }}
                      >
                        <Select
                          name="phFlag"
                          label=""
                          labelkey={"l"}
                          id={"wc"}
                          className={`sort-dropdown ${
                            values?.phFlag?.v == 0
                              ? "mni-selected-dropdown"
                              : ""
                          }`}
                          options={corrosivityOpts.options}
                          selected={values?.phFlag}
                          onSelect={(e) => {
                            if (e.l === "N/A") {
                              setFieldValue("phExactValue", "")
                            }
                            setFieldValue("phFlag", e)
                          }}
                          disabled={type === "viewprofile"}
                        />
                        <div
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "absolute",
                            top: 40,
                            // paddingTop: "40px",
                          }}
                        >
                          {errors.phFlag && touched.phFlag ? (
                            <div style={{ marginLeft: "-9px" }}>
                              Corrosovity is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        // className={classnames(
                        //   "form-input-block",
                        //   errors.phFlag ? "mt-4" : "mt-4"
                        // )}
                        className="form-input-block mt-4"
                        style={{ width: "90%", position: "relative", left: 6 }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.actual" />
                        </span>
                        <Input
                          name="phExactValue"
                          // value={values?.phExactValue}
                          value={
                            values?.phFlag &&
                            (values?.phFlag.v === 0 ||
                              values?.phFlag.v === null)
                              ? (values["phExactValue"] = "")
                              : values?.phExactValue
                          }
                          type="number"
                          disabled={
                            values?.phFlag &&
                            (values?.phFlag.v === 0 ||
                              values?.phFlag.v === null)
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("phExactValue", value)
                            }
                          }}
                          onBlurChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("phExactValue", value || "")
                            }
                          }}
                        />
                        <div
                          style={{ color: "red", fontSize: 10, paddingTop: 10 }}
                        >
                          {errors.phExactValue && touched.phExactValue ? (
                            <div>{errors.phExactValue}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ padding: "0 0rem" }}>
                      <div
                        className="form-input-block ml-2 mni-style"
                        style={{ width: "90%", marginBottom: 85 }}
                      >
                        <Select
                          name="btuPerLbs"
                          label=""
                          labelkey={"l"}
                          id={"wc"}
                          className={`sort-dropdown ${
                            values?.btuPerLbs?.v == 0
                              ? "mni-selected-dropdown"
                              : ""
                          }`}
                          options={btulbOpts.options}
                          selected={values?.btuPerLbs}
                          onSelect={(e) => {
                            if (e.l === "N/A") {
                              setFieldValue("btuActual", "")
                            }
                            setFieldValue("btuPerLbs", e)
                          }}
                          disabled={type === "viewprofile"}
                        />
                        <div
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "absolute",
                            top: 40,
                            // paddingTop: "40px",
                          }}
                        >
                          {errors.btuPerLbs && touched.btuPerLbs ? (
                            <div>BTU/LB is required</div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="form-input-block mt-4"
                        style={{ width: "90%", position: "relative", left: -2 }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.actual" />
                        </span>
                        <Input
                          name="btuActual"
                          // value={values?.btuActual}
                          value={
                            values?.btuPerLbs &&
                            (values?.btuPerLbs.v === 0 ||
                              values?.btuPerLbs.v === null)
                              ? (values["btuActual"] = "")
                              : values?.btuActual
                          }
                          type="number"
                          disabled={
                            values?.btuPerLbs &&
                            (values?.btuPerLbs.v === 0 ||
                              values?.btuPerLbs.v === null)
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("btuActual", value)
                            }
                          }}
                          onBlurChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("btuActual", value || "")
                            }
                          }}
                        />
                        <div
                          style={{ color: "red", fontSize: 10, paddingTop: 10 }}
                        >
                          {errors.btuActual && touched.btuActual ? (
                            <div>{errors.btuActual}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ padding: "0 0rem" }}>
                      <div
                        className="form-input-block ml-2 mni-style bp-dropdown-height"
                        style={{ marginBottom: 85 }}
                      >
                        <Select
                          name="boilingPointFlag"
                          label=""
                          labelkey={"l"}
                          id={"wc"}
                          options={boilingPointOpts.options}
                          className={`sort-dropdown ${
                            values?.boilingPointFlag?.v == 0
                              ? "mni-selected-dropdown"
                              : ""
                          }`}
                          selected={values?.boilingPointFlag}
                          // selected={values?.boilingPointFlag.v === null ?
                          //     values["boilingPointFlag"] = {l: "N/A", v: 0}
                          //         : values?.boilingPointFlag}
                          onSelect={(e) => {
                            if (e.l === "N/A") {
                              setFieldValue("boilingPtActual", "")
                              setFieldValue("boilingScale", "")
                            }
                            setFieldValue("boilingPointFlag", e)
                          }}
                          disabled={type === "viewprofile"}
                        />
                        <div
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "absolute",
                            top: 40,
                            // paddingTop: "40px",
                          }}
                        >
                          {errors?.boilingPointFlag &&
                          touched?.boilingPointFlag ? (
                            <div style={{ marginLeft: "-9px" }}>
                              Boiling Point is required
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="form-input-block mt-4"
                        style={{
                          // marginTop: "1.8rem",
                          width: "90%",
                          // position: "relative",
                          // left: -5,
                        }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.actual" />
                        </span>
                        <Input
                          name="boilingPtActual"
                          // value={values?.boilingPtActual}
                          value={
                            values?.boilingPointFlag &&
                            (values?.boilingPointFlag.v === 0 ||
                              values?.boilingPointFlag.v === null)
                              ? (values["boilingPtActual"] = "")
                              : values?.boilingPtActual
                          }
                          type="number"
                          disabled={
                            values?.boilingPointFlag &&
                            (values?.boilingPointFlag.v === 0 ||
                              values?.boilingPointFlag.v === null)
                          }
                          onFieldChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("boilingPtActual", value)
                            }
                          }}
                          onBlurChange={(e) => {
                            const { value } = e.target
                            if (/^(<|>)?\d*\.?\d*$/.test(value)) {
                              setFieldValue("boilingPtActual", value || "")
                            }
                          }}
                        />
                        <div
                          style={{ color: "red", fontSize: 10, paddingTop: 10 }}
                        >
                          {errors.boilingPtActual && touched.boilingPtActual ? (
                            <div>{errors.boilingPtActual}</div>
                          ) : null}
                        </div>
                        <div
                          className="d-flex mt-2"
                          style={{ justifyContent: "center" }}
                        >
                          <span className="d-flex">
                            {/* <Radio name="boilingScale" /> */}
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="boilingScale"
                              // value={values?.boilingScale}
                              value={
                                values?.boilingPointFlag.v === 0
                                  ? (values.boilingScale = "")
                                  : values?.boilingScale
                              }
                              checked={
                                values?.boilingScale === "F" ? true : false
                              }
                              onChange={() => {
                                setFieldValue("boilingScale", "F")
                              }}
                            />
                            <span className="ml-3" style={{ fontSize: 12 }}>
                              <FormattedMessage id="profile.degreeF" />
                            </span>
                          </span>
                          <span className="d-flex ml-2">
                            {/* <Radio name="boilingScale" /> */}
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="boilingScale"
                              // value={values?.boilingScale}
                              value={
                                values?.boilingPointFlag.v === 0
                                  ? (values.boilingScale = "")
                                  : values?.boilingScale
                              }
                              checked={
                                values?.boilingScale === "C" ? true : false
                              }
                              onChange={() => {
                                setFieldValue("boilingScale", "C")
                              }}
                            />
                            <span className="ml-3" style={{ fontSize: 12 }}>
                              <FormattedMessage id="profile.degreeC" />
                            </span>
                          </span>
                        </div>
                        <div
                          style={{ color: "red", fontSize: 10, paddingTop: 10 }}
                        >
                          {values?.boilingPointFlag?.v !== 0 &&
                          values?.boilingPtActual !== undefined &&
                          values?.boilingPtActual !== null &&
                          String(values?.boilingPtActual).trim() !== "" &&
                          (values?.boilingScale === undefined ||
                            values?.boilingScale === null ||
                            String(values?.boilingScale).trim() === "") ? (
                            <div>Degree is required</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>
                    <hr />
                  </span>
                  <div className="d-flex">
                    <div className="col-sm-4">
                      <div className="mt-4" style={{ top: "16px" }}>
                        <div className="d-flex col-sm-12">
                          <div
                            className="label col-sm-5 pl-0 text-left"
                            style={{ fontSize: 12 }}
                          >
                            <FormattedMessage id="profile.solid" />
                            <span style={{ color: "red" }}>{"*"}</span>
                          </div>
                          <div className="form-input-block col-sm-7">
                            <span className="label">
                              <FormattedMessage id="profile.percentage" />
                            </span>
                            <Input
                              name="solidPct"
                              value={(values?.solidPct).trim()}
                              type="number"
                              onFieldChange={(e) => {
                                const { value } = e.target
                                const isValid = /^\d*-?\d*$/.test(value)
                                // const ranges = value.split("-")
                                // const isRangeValid = ranges.length === 2 ? (ranges[0] < ranges[1]) : ranges[0] > 0
                                if (isValid) {
                                  setFieldValue("solidPct", value)
                                }
                              }}
                              onBlurChange={(e) => {
                                const { value } = e.target
                                const isValid = /^\d*-?\d*$/.test(value)
                                if (isValid) {
                                  setFieldValue("solidPct", value)
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div
                          // className="col-sm-12"
                          style={{
                            marginLeft: 90,
                            color: "red",
                            fontSize: 10,
                            paddingTop: 10,
                          }}
                        >
                          {errors.solidPct && touched.solidPct ? (
                            <span>{errors.solidPct}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-4" style={{ top: "16px" }}>
                        <div className="d-flex col-sm-12">
                          <div
                            className="label col-sm-5 pl-0 text-left"
                            style={{ fontSize: 12 }}
                          >
                            <FormattedMessage id="profile.sludge" />
                            <span style={{ color: "red" }}>{"*"}</span>
                          </div>
                          <div className="form-input-block col-sm-7">
                            <span className="label">
                              <FormattedMessage id="profile.percentage" />
                            </span>
                            <Input
                              name="sludgesPct"
                              value={(values?.sludgesPct).trim()}
                              type="number"
                              onFieldChange={(e) => {
                                const { value } = e.target
                                const isValid = /^\d*-?\d*$/.test(value)
                                if (isValid) {
                                  setFieldValue("sludgesPct", value)
                                }
                              }}
                              onBlurChange={(e) => {
                                const { value } = e.target
                                const isValid = /^\d*-?\d*$/.test(value)
                                if (isValid) {
                                  setFieldValue("sludgesPct", value)
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div
                          // className="col-sm-12"
                          style={{
                            marginLeft: 90,
                            color: "red",
                            fontSize: 10,
                            paddingTop: 10,
                          }}
                        >
                          {errors.sludgesPct && touched.sludgesPct ? (
                            <span>{errors.sludgesPct}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="d-flex col-sm-12">
                          <div
                            className="label col-sm-5 pl-0 text-left"
                            style={{ fontSize: 12 }}
                          >
                            <FormattedMessage id="profile.freeliquid" />
                            <span style={{ color: "red" }}>{"*"}</span>
                          </div>
                          <div className="form-input-block col-sm-7">
                            <span className="label">
                              <FormattedMessage id="profile.percentage" />
                            </span>
                            <Input
                              name="freeLiquidsPct"
                              value={values?.freeLiquidsPct.trim()}
                              type="number"
                              onFieldChange={(e) => {
                                const { value } = e.target
                                const isValid = /^\d*-?\d*$/.test(value)
                                if (isValid) {
                                  setFieldValue("freeLiquidsPct", value)
                                }
                              }}
                              onBlurChange={(e) => {
                                const { value } = e.target
                                const isValid = /^\d*-?\d*$/.test(value)
                                if (isValid) {
                                  setFieldValue("freeLiquidsPct", value)
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div
                          // className="col-sm-12"
                          style={{
                            marginLeft: 90,
                            color: "red",
                            fontSize: 10,
                            paddingTop: 10,
                          }}
                        >
                          {errors.freeLiquidsPct && touched.freeLiquidsPct ? (
                            <span>{errors.freeLiquidsPct}</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="dsp-flx col-sm-1 col-md-1 col-lg-1 text-center mt-4"
                      style={{ justifyContent: "space-around" }}
                    >
                      <span>
                        <FormattedMessage id={"profile.ortext"} />
                      </span>
                    </div>
                    <div className="col-sm-8 py-4">
                      <div className="dsp-flx col-sm-8 col-md-8 col-lg-8 text-left py-2 position-relative mt-1">
                        <div className="d-flex col-sm-3">
                          <label className="check-container">
                            <input
                              type="checkbox"
                              checked={values?.powderFlag}
                              onChange={(e) => {
                                setFieldValue("powderFlag", e.target.checked)
                              }}
                              name="powderFlag"
                            />
                            <span className={"checkmark"} />
                          </label>
                          <span
                            className="pl-4 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.powder"} />
                          </span>
                        </div>
                        <div className="d-flex col-sm-3">
                          <label className="check-container">
                            <input
                              type="checkbox"
                              checked={values?.monolithFlag}
                              onChange={(e) => {
                                setFieldValue("monolithFlag", e.target.checked)
                              }}
                              name="monolithFlag"
                            />
                            <span className={"checkmark"} />
                          </label>
                          <span
                            className="pl-4 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.monolith"} />
                          </span>
                        </div>
                        <div className="d-flex col-sm-3">
                          <label className="check-container">
                            <input
                              type="checkbox"
                              checked={values?.debris}
                              onChange={(e) => {
                                setFieldValue("debris", e.target.checked)
                              }}
                              name="debris"
                            />
                            <span className={"checkmark"} />
                          </label>
                          <span
                            className="pl-4 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.debris"} />
                          </span>
                        </div>
                        <div className="d-flex col-sm-6">
                          <label className="check-container">
                            <input
                              type="checkbox"
                              checked={values?.compressedGas}
                              onChange={(e) => {
                                setFieldValue("compressedGas", e.target.checked)
                              }}
                              name="compressedGas"
                            />
                            <span className={"checkmark"} />
                          </label>
                          <span
                            className="pl-4 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.compressedgas"} />
                          </span>
                        </div>
                      </div>
                      {values?.solidPct?.trim() == "0" &&
                      values?.sludgesPct?.trim() == "0" &&
                      values?.freeLiquidsPct?.trim() == "0" &&
                      // ((errors?.solidPct || touched?.solidPct) || (errors?.sludgesPct || touched?.sludgesPct) || (errors?.freeLiquidsPct || touched?.freeLiquidsPct)) &&
                      values?.powderFlag === false &&
                      values?.monolithFlag === false &&
                      values?.debris === false &&
                      values?.compressedGas === false ? (
                        <div className="dsp-flx col-sm-8 col-md-8 col-lg-8 text-center py-2 text-left position-relative mt-1">
                          <div
                            style={{
                              marginLeft: 20,
                              color: "red",
                              fontSize: 10,
                              paddingTop: 10,
                            }}
                          >
                            {
                              "When Solids, Sludges, FreeLiquids are 0, please select any one among Powder, Monolith, Debris, Compressed Gas"
                            }
                          </div>
                        </div>
                      ) : null}
                      <div className="col-sm-8 d-flex mt-4">
                        <span
                          className="label col-sm-4 pl-0"
                          style={{ fontSize: 12 }}
                        >
                          <FormattedMessage id="profile.water" />
                        </span>
                        <div className="form-input-block col-sm-8 mr-2">
                          <span className="label">
                            <FormattedMessage id="profile.percentage" />
                          </span>
                          <Input
                            name="waterPercentage"
                            value={values?.waterPercentage}
                            type="number"
                            onFieldChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue("waterPercentage", value)
                              }
                            }}
                            onBlurChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue(
                                  "waterPercentage",
                                  parseInt(value) || ""
                                )
                              }
                            }}
                          />
                          <span
                            style={{
                              color: "red",
                              fontSize: 10,
                              paddingTop: 10,
                            }}
                          >
                            {errors.waterPercentage &&
                            touched.waterPercentage ? (
                              <span>{errors.waterPercentage}</span>
                            ) : null}
                          </span>
                          <div className="d-flex mt-2">
                            <label className="check-container">
                              <input
                                type="checkbox"
                                checked={values?.pumpableWasteFlag}
                                onChange={(e) => {
                                  setFieldValue(
                                    "pumpableWasteFlag",
                                    e.target.checked
                                  )
                                }}
                                name="pumpableWasteFlag"
                              />
                              <span className={"checkmark"} />
                            </label>
                            <span
                              className="pl-4 pr-0 text-description"
                              style={{ marginLeft: "16PX" }}
                            >
                              <FormattedMessage id={"profile.pumpable"} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>
                    <hr />
                  </span>
                  <div className="d-flex col-sm-12 col-md-12 col-lg-12 text-left p-0 py-2 position-relative mt-5">
                    <div className="col-sm-7">
                      <div
                        className="d-flex"
                        style={{ marginLeft: 20, marginRight: -20 }}
                      >
                        <div className="col-sm-4">
                          <span className=" text-description p-1">
                            <FormattedMessage id="profile.odor" />
                          </span>
                          <span style={{ color: "red" }}>{"*"}</span>
                        </div>
                        <div className="col-sm-4">
                          <span className=" text-description p-1">
                            <FormattedMessage id="profile.color" />
                          </span>
                          <span style={{ color: "red" }}>{"*"}</span>
                        </div>
                        <div className="col-sm-4">
                          <span className=" text-description p-1">
                            <FormattedMessage id="profile.halogens" />
                          </span>
                          <span style={{ color: "red" }}>{"*"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex col-sm-12 col-md-12 col-lg-12 text-left p-0 py-2 position-relative ">
                    <div className="col-sm-7">
                      <div
                        className="d-flex"
                        style={{ marginLeft: 6, marginRight: 20 }}
                      >
                        <div className="col-sm-4">
                          <div className="form-input-block  ml-2 pb-3 mb-dropdown mni-style odor-dropdown-height">
                            <Select
                              name="odorFlag"
                              label=""
                              labelkey={"l"}
                              selected={values?.odorFlag}
                              options={odorOpts.options}
                              className={`sort-dropdown ${
                                values?.odorFlag?.v == ""
                                  ? "mni-selected-dropdown"
                                  : ""
                              }`}
                              onSelect={(e) => {
                                setFieldValue("odorFlag", e)
                              }}
                              disabled={type === "viewprofile"}
                            />
                          </div>
                          <div
                            style={{
                              color: "red",
                              fontSize: 10,
                              marginLeft: 15,
                            }}
                          >
                            {errors.odorFlag && touched.odorFlag ? (
                              <div>Odor is required</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-input-block">
                            <Input
                              name="colorDesc"
                              value={values?.colorDesc}
                              noMaxLength={false}
                              charlen={50}
                              onFieldChange={(e) => {
                                setFieldValue("colorDesc", e.target.value)
                              }}
                            />
                          </div>
                          <div
                            style={{
                              color: "red",
                              fontSize: 10,
                              paddingTop: 10,
                              marginLeft: 25,
                            }}
                          >
                            {errors.colorDesc && touched.colorDesc ? (
                              <div>Color is required</div>
                            ) : null}
                          </div>
                        </div>
                        <div className=" col-sm-4">
                          <div className="form-input-block">
                            <Input
                              name="halogensPct"
                              noMaxLength={false}
                              charlen={50}
                              value={values?.halogensPct}
                              placeholder={"_ to _ %"}
                              onFieldChange={(e) => {
                                setFieldValue("halogensPct", e.target.value)
                              }}
                            />
                          </div>
                          <div
                            style={{
                              color: "red",
                              fontSize: 10,
                              paddingTop: 10,
                              marginLeft: 20,
                            }}
                          >
                            {errors.halogensPct && touched.halogensPct ? (
                              <div>Halogens is required</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-input-block col-sm-8 mt-4">
                        <span className="label">
                          <FormattedMessage id="profile.odordescription" />
                        </span>
                        <TextArea
                          name="odorDesc"
                          value={values?.odorDesc}
                          onFieldChange={(e) => {
                            setFieldValue("odorDesc", e.target.value)
                          }}
                          noMaxLength={false}
                          maxLength={50}
                          rows={2}
                        />
                      </div>
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          paddingTop: 10,
                          marginLeft: 25,
                        }}
                      >
                        {errors.odorDesc &&
                        touched.odorDesc &&
                        values?.odorFlag?.id === 1 &&
                        values?.odorDesc?.length === 0 ? (
                          <div>
                            Odor Description is required when odor is mild
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="col-sm-5 d-flex pr-0"
                      style={{ marginLeft: "-50px" }}
                    >
                      <div className="col-sm-5 d-flex">
                        <span className="mr-4">
                          <FormattedMessage id={"profile.ortext"} />
                        </span>
                        <div className="d-flex mt-2">
                          <label className="check-container">
                            <input
                              type="checkbox"
                              checked={values?.noneFlag}
                              onChange={(e) => {
                                setFieldValue("noneFlag", e.target.checked)
                              }}
                              name="noneFlag"
                            />
                            <span className={"checkmark"} />
                          </label>
                          <span
                            className="pl-4 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.none"} />
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-7 pr-0">
                        <div className="d-flex mt-2">
                          <label className="check-container">
                            <Radio
                              name="wasteCharNoSelectionFlag"
                              isChecked={values?.wasteCharNoSelectionFlag}
                              onChange={() =>
                                handleRadioChange("wasteCharNoSelectionFlag")
                              }
                            />
                          </label>
                          <span
                            className="pl-2 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.noselection"} />
                          </span>
                        </div>
                        <div className="d-flex mt-2">
                          <label className="check-container">
                            <Radio
                              name="voc100Ppm"
                              isChecked={values?.voc100Ppm}
                              onChange={() => handleRadioChange("voc100Ppm")}
                            />
                          </label>
                          <span
                            className="pl-2 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.voc100"} />
                          </span>
                        </div>
                        <div className="d-flex mt-2">
                          <label className="check-container">
                            <Radio
                              name="voc500Ppm"
                              isChecked={values?.voc500Ppm}
                              onChange={() => handleRadioChange("voc500Ppm")}
                            />
                          </label>
                          <span
                            className="pl-2 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.voc500"} />
                          </span>
                        </div>
                        <div className="d-flex mt-3">
                          <label className="check-container">
                            <input
                              type="checkbox"
                              name="subjectToSubpartCcFlag"
                              checked={values?.subjectToSubpartCcFlag}
                              onChange={(e) => {
                                setFieldValue(
                                  "subjectToSubpartCcFlag",
                                  e.target.checked
                                )
                              }}
                            />
                            <span
                              className={"checkmark"}
                              style={{ marginLeft: "-12px" }}
                            />
                          </label>
                          <span
                            className="pl-2 pr-0 text-description"
                            style={{ marginLeft: "16PX" }}
                          >
                            <FormattedMessage id={"profile.subjectsubpart"} />
                          </span>
                        </div>
                        <div className="dsp-flx mt-2">
                          <span
                            className="label col-sm-4 pl-0"
                            style={{ fontSize: 12, bottom: "-20px" }}
                          >
                            <FormattedMessage id="profile.toc" />
                          </span>
                          <div className="form-input-block col-sm-8 mni-style toc-dropdown-height">
                            <Select
                              name="tocPercentage"
                              label=""
                              labelkey={"l"}
                              options={tocOpts.options}
                              selected={values?.tocPercentage}
                              className={`sort-dropdown ${
                                values?.tocPercentage?.v == 1
                                  ? "mni-selected-dropdown"
                                  : ""
                              }`}
                              onSelect={(e) => {
                                setFieldValue("tocPercentage", e)
                              }}
                              disabled={type === "viewprofile"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Back, Save, Cancel Actions */}
              {type !== "viewprofile" && (
                <div className="row m-3 new-row-block mt-4">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2">
                    <div
                      className="row m-0 float-left"
                      style={{ paddingLeft: 5 }}
                    >
                      <button
                        type="button"
                        className={`cancel-button`}
                        onClick={() => backAction()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        ref={ref}
                        type="submit"
                        className={`green-button`}
                        // onClick={() => submit()}
                      >
                        Save & Continue
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => cancelAction()}
                      >
                        Cancel
                      </button>
                    </div>
                    <span className="float-right text-red m-r-2r mt-2">
                      {`*Required Fields`}
                    </span>
                  </div>
                  <AlertModal
                    showError={showError}
                    seekingStep={seekingStep}
                    setShowError={setShowError}
                    submitAction={() =>
                      handleAlerts(values, errors, isValid, seekingStep)
                    }
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                  {/* Add one more Alert to show for invalid or partial save errors?.length > 0 || !isValid */}
                  {/* <AlertModal submitAction={() => handleAlerts(values, errors, isValid, seekingStep)} showAlert={showError} setShowAlert={setShowAlert} /> */}
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}
export default wastecharacterenhancer(WasteCharacteristics)
