import React from "react"
import Image from "shared/HelpImage/index"

function ProfileOverview() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Profile Overview</h3>
      {/* <Image src="profileoverview_landingpage.png" /> */}
      <p className="text-justify pl-3 pt-2">
        A Profile Overview is on display classified by five different statuses
        including: Approved, Expired, Pending Customer Response, Pending Clean
        Earth Approval, and Expiring Soon.
      </p>
      <Image src="dashboard_landing_page.png" />
      <p className="text-justify pl-3 pt-2">
        You can click on these five different status types to view profiles that
        fall under these categories. You also have the ability in the top right
        corner to sort by the Last 30 Days, Last 60 Days, or Last 90 days.
      </p>
      <Image src="profile_overview_tabs.png" />
      <p className="text-justify pl-3 pt-2">
        You can sort each field of the Profile Overview table by clinking on the
        arrow in each field to sort in alphabetical (or date) order.
      </p>
      <Image src="dashboard_table_header.png" />
      <p className="text-justify pl-3 pt-2">
        You can click on any profile number to view the full profile details.
      </p>
      <Image src="profile_results_dashboard.png" />
      <p className="text-justify pl-3 pt-2">
        Under the Actions field for each profile, there are 5 different icons
        that you can click on to complete the following actions respectively:
        Copy, Print, Download, Recertify, Print Label.
      </p>
      <Image src="profile_actions.png" />
    </div>
  )
}
export default ProfileOverview
