import React from "react"
import Image from "shared/HelpImage/index"

function SearchProfile() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Search Profile</h3>

      <p className="text-justify pl-3 pt-2">
        A profile can be searched from generator, profile, expire date, profile
        status, waste name and universal waste.
      </p>
      <Image src="Search Profile.png" />
    </div>
  )
}
export default SearchProfile
