import React from "react"
import Image from "shared/HelpImage/index"

function ActionsInGenerator() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Generator Actions</h3>

      <p className="text-justify pl-3 pt-2">
        In the actions field on your generator search results, you can select
        the create profile button. This will prompt you to create a new profile
        with a completely blank form or to use a template. If you select
        template, a new popup will appear with a drop-down list of predefined
        templates for you to choose from.
      </p>
      <Image src="mni_generator_actions.png" />
      <p className="pt-2 text-justify pl-3">Selecting ‘From Template’:</p>
      <Image src="generator_profile_from_template.png" />
    </div>
  )
}
export default ActionsInGenerator
