import React from "react"
import Image from "shared/HelpImage/index"

function PendingCleanEarthApproval() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        Profiles pending with Clean Earth approval
      </h3>

      <p className="text-justify pl-3 pt-2">
        Profiles that are pending with Clean Earth approval under this account
        are shown as below.
      </p>
      <p className="text-justify pl-3 pt-2">
        Can select a filter of Last 30 days, Last 60 days, Last 90 days to see
        the corresponding profile data
      </p>
      <p className="text-justify pl-3 pt-2">
        When Pending Clean Earth Approval tab is clicked, profiles that are
        pending with Clean Earth approval under this account in last 30 days
        will be shown.
      </p>
      <Image src="profileoverview_pendingce_last30.png" />
      <p className="text-justify pl-3 pt-2">
        When Pending Clean Earth Approval tab is clicked, profiles that are
        pending with Clean Earth approval under this account in last 60 days
        will be shown.
      </p>
      <Image src="profileoverview_pendingce_last60.png" />
      <p className="text-justify pl-3 pt-2">
        When Pending Clean Earth Approval tab is clicked, profiles that are
        pending with Clean Earth approval under this account in last 90 days
        will be shown.
      </p>
      <Image src="profileoverview_pendingce_last90.png" />
    </div>
  )
}
export default PendingCleanEarthApproval
