import React from "react"
import Image from "shared/HelpImage/index"

function USEPAStateRegulatoryInformation() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">
        US/EPA State Regulatory Information
      </h3>

      <p className="text-justify pl-3 pt-2">
        Fill out all required fields marked with{" "}
        <strong className="p-0 m-0" style={{ color: "red" }}>
          *
        </strong>{" "}
        and other fields as necessary. The following three fields have an edit
        button next to them that will display a pop up when selected: EPA Waste
        Code, EPA UHCs, State Code(s). Make the appropriate selection(s) from
        the Available list and select Save for each field.
      </p>
      <Image src="mni_statreginfo1.png" />
      <br />
      <br />
      <br />
      <Image src="edit_epa_codes.png" />
      <p className="text-justify pl-3 pt-2">
        *Please Note: Texas Waste State Code will only be available to populate
        if generator is located in Texas and must be selected if working in
        Texas
      </p>
      <p className="text-justify pl-3 pt-2">
        Continue filling out required fields and select Save & Continue.
      </p>
      <Image src="mni_statereginfo2.png" />
      <br />
      <br />
      <br />
      <Image src="mni_statereginfo3.png" />
    </div>
  )
}
export default USEPAStateRegulatoryInformation
