import React, { Fragment } from "react"
import enhancer from "./enhancer"
import "./style.scss"
import Image from "shared/Image"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import classnames from "classnames"
import Loading from "shared/Loading"
import { getCompanyInitial } from "shared"
import EllipsisText from "react-ellipsis-text"
import { useSelector } from "react-redux"

function Sidebar(props) {
  let {
    user,
    isTablet,
    onLogosClick,
    onEditProfileClick,
    userRole,
    isStoreManager,
    isActive,
    navigate,
    storeId,
    sideNav,
    isQuickTourCompleted,
    isActiveSubMenu,
    showSubMenu,
    profile,
    BusinessSegmentId,
    knowyourc,
    loading,
  } = props

  const { firstName, lastName, customer_name } = user
  const { changeCustomer } = useSelector((state) => state)
  let nameInitail, name
  if (isStoreManager) {
    nameInitail = getCompanyInitial(customer_name)
    name = nameInitail + " " + storeId
  } else {
    nameInitail = lastName?.charAt(0) + firstName?.charAt(0)
    name = user?.firstName + " " + user?.lastName
  }

  return (
    <Fragment>
      <div
        className={` leftnav-wrapper ${
          isTablet
            ? "isTablet col-sm-4  col-md-4 col-lg-4"
            : "col-sm-3  col-md-3 col-lg-3 col-cust-left"
        }`}
      >
        <Loading id="location_details">
          <div className="row">
            <div className="col-12 p-0 sidebarwrap">
              <div
                className={classnames("row custsec", {
                  "align-items-center": isStoreManager,
                })}
                id="profile"
              >
                {!isStoreManager && (
                  <div
                    className={` col-sm-4 col-md-4 col-lg-4 ${
                      user && user.role && user.role.profile_pic
                        ? ""
                        : "storesec"
                    }`}
                  >
                    {(user && user.role && user.role.profile_pic && (
                      <span className="storeIcon">
                        <Image
                          className="profileimg"
                          src={user && user.role && user.role.profile_pic}
                          alt=""
                          height="34px"
                          width="534px"
                        />{" "}
                        <Image
                          className="edit"
                          src="ic_edit_2.svg"
                          alt=""
                          height="20px"
                          width="20px"
                          onClick={onEditProfileClick}
                        />
                      </span>
                    )) || (
                      <span className="storeIcon">
                        {user && nameInitail}
                        <Image
                          className="edit"
                          src="ic_edit_2.svg"
                          alt=""
                          height="20px"
                          width="20px"
                          onClick={onEditProfileClick}
                        />
                      </span>
                    )}
                  </div>
                )}
                {!isStoreManager && (
                  <div className="col-sm-8 col-md-8 col-lg-8 sidebarcust text-left">
                    <label className="storepersonname ">{name}</label>
                    <label className="storeid  m-0 d-block text-capitalize ">
                      {user?.title}
                    </label>
                  </div>
                )}
                {isStoreManager && (
                  <div className="col-sm-12 col-md-12 col-lg-12 sidebarcust text-left">
                    <div className="d-flex addrs-sec">
                      <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6 whiteSpaceNowrap pl-2">
                          <h5 className="store-num bold mb-2 px-3 pt-1 ">
                            {_.isEmpty(storeId) ? nameInitail : storeId}
                          </h5>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 whiteSpaceNowrap">
                          <div className="storeNum-pipeIcon pl-1 ml-4 ">
                            <div className="smallFont p-1 ">
                              <EllipsisText text={customer_name} length={13} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {loading === false ? (
                <div id="sideNav">
                  {sideNav?.length > 0 &&
                    sideNav.map((s, i) => {
                      let isActivePath = isActive(s.path)
                      return (
                        <Fragment key={i}>
                          {(!s.role || s.role?.includes(userRole)) && (
                            <div
                              className={`heightrow ${
                                profile?.businessSegmentId ===
                                  BusinessSegmentId?.FULL_CIRCLE ||
                                profile?.businessSegmentId ===
                                  BusinessSegmentId["M&I"] ||
                                isQuickTourCompleted
                                  ? isActivePath
                                    ? "active-tab"
                                    : ""
                                  : ""
                              }`}
                            >
                              <div
                                key={i}
                                className="row"
                                onClick={() =>
                                  navigate(s.path, Boolean(s.subMenu))
                                }
                                id={
                                  s.label === "sidebar.support" ? "support" : ""
                                }
                              >
                                <div className="col-sm-4 col-md-4 col-lg-4 iconsec">
                                  <Image
                                    src={s.icon}
                                    height="34px"
                                    width="34px"
                                    alt=""
                                  />
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-8 icon-label text-left">
                                  <label className="m-0 sidebarlabel">
                                    <FormattedMessage id={s.label} />
                                  </label>
                                  {s.subMenu && s.subMenu.length > 0 && (
                                    <span
                                      className={`sub-menu-arrows ${
                                        isActivePath && showSubMenu
                                          ? "down"
                                          : "right"
                                      }`}
                                    />
                                  )}
                                </div>
                              </div>

                              {s.subMenu &&
                                showSubMenu &&
                                isActivePath &&
                                s.subMenu.length > 0 &&
                                s.subMenu.map((m, i) => (
                                  <div
                                    key={i}
                                    onClick={() =>
                                      navigate(m.path, false, true)
                                    }
                                    className={`${
                                      isActiveSubMenu(m.path)
                                        ? "active-sunMenu"
                                        : ""
                                    }`}
                                  >
                                    <div className="sub-menu text-left offset-3 col-8 pl-3">
                                      <FormattedMessage id={m.label} />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </Fragment>
                      )
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </Loading>
        {changeCustomer?.customer?.businessSegmentId ===
          BusinessSegmentId["M&I"] && (
          <div className="knowyourcsr">
            <div className="csrrectangle"></div>
            {knowyourc != undefined && (
              <div>
                <div className="csr_email">
                  {knowyourc != undefined || knowyourc.email != undefined ? (
                    <a href={`mailto:${knowyourc.email}`}>
                      <FormattedMessage id="csr.sendemail" />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div className="csr_phone">
                  {knowyourc != undefined || knowyourc.phone != undefined
                    ? knowyourc.phone
                    : ""}
                </div>
                <div className="csr_name">
                  {knowyourc != undefined || knowyourc.name != undefined
                    ? knowyourc.name
                    : ""}
                </div>
              </div>
            )}
            <div className="csr_rectangle"></div>
            <Image className="csrcaller" src="caller_icon.svg" />
            <Image className="csrperson" src="csr_icon.svg" />
            <div className="csrcontact-us">CONTACT US</div>
            <Image className="csrphone" src="phone_icon.svg" />
            <Image className="csremail" src="email_icon.svg" />
            <Image className="csrsupport-pic" src="csr_user_icon.svg" />
          </div>
        )}
        {changeCustomer?.customer?.businessSegmentId ===
          BusinessSegmentId["M&I"] && (
          <div>
            <Image
              className="logoimg"
              src="logo_Clean_earth.svg"
              alt=""
              onClick={() => {
                onLogosClick()
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}
export default enhancer(Sidebar)
