import React from "react"
import Image from "shared/HelpImage/index"

function CreateProfileFromBlank() {
  return (
    <div>
      <h3 className="pt-4 text-left pl-3">Create Profile From Blank</h3>

      <p className="text-justify pl-3 pt-2">
        When Create Profile from Blank is clicked, this is the landing page, the
        stepper shown in the below image represents each step of creating the
        profile.
      </p>
      <Image src="mni_createprofilefromblank.png" />
    </div>
  )
}
export default CreateProfileFromBlank
